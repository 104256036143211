<script lang="ts" setup>
import { computed, ComputedRef } from "vue";

import { animalIcons } from "../utils";
import { useAuthStore } from "../stores/auth";
import { IUserToken } from "../stores/types";

const authStore = useAuthStore();

const activeUser: ComputedRef<IUserToken | null> = computed(() => authStore.token);
</script>

<template>
  <div class="home">
    <h1 class="title has-text-centered has-text-grey-lighter">Hallo {{ activeUser.displayName }}!</h1>
    <p class="subtitle has-text-centered has-text-grey-lighter">Bitte wähle einen Menüpunkt aus.</p>
    <p v-if="activeUser.animal" class="has-text-centered has-text-grey-lighter pt-6">
      <span class="icon">
        <FontAwesomeIcon :icon="animalIcons[activeUser.animal]" size="6x"></FontAwesomeIcon>
      </span>
    </p>
  </div>
</template>

<style scoped></style>
