import * as Sentry from "@sentry/vue";
import { reportingObserverIntegration } from "@sentry/integrations";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

const app = createApp(App);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({
      router,
    }),
    reportingObserverIntegration(),
  ],
  tracesSampleRate: 1.0,
  trackComponents: true,
  timeout: 4000,
  release: import.meta.env.VITE_BUILD_REVISION,
  environment: import.meta.env.MODE,
  tracePropagationTargets: ["localhost", "local-dev.ameo.io", "heimdall.dev.ameo.io", "heimdall.ameo.io", /^\//],
});

import { OrugaOptions, Oruga } from "@oruga-ui/oruga-next";

import { bulmaConfig } from "@oruga-ui/theme-bulma";

import timeago from "vue-timeago3";
import { de } from "date-fns/locale";

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faExclamationTriangle,
  faCircleNotch,
  faArrowUp,
  faTimes,
  faAngleRight,
  faCaretRight,
  faArrowRightArrowLeft,
  faBullseyeArrow,
  faCheck,
  faCheckCircle,
  faInfoCircle,
  faExclamationCircle,
  faAngleLeft,
  faAngleDown,
  faEye,
  faEyeSlash,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/pro-duotone-svg-icons";

import { faAmazon } from "@fortawesome/free-brands-svg-icons";

library.add(
  faExclamationTriangle,
  faCircleNotch,
  faArrowUp,
  faTimes,
  faAngleRight,
  faCaretRight,
  faArrowRightArrowLeft,
  faBullseyeArrow,
  faAmazon,

  faCheck,
  faCheckCircle,
  faInfoCircle,
  faExclamationCircle,
  faAngleLeft,
  faAngleDown,
  faEye,
  faEyeSlash,
  faCaretDown,
  faCaretUp,
);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import "./scss/main.scss";
import { createPinia } from "pinia";

/*
const themeOverrides = {
  common: {
    bodyColor: "#191919",
    primaryColor: "#FED32C",
    primaryColorHover: "#FFEB9D",
    tableColor: "#282828",
    baseColor: "#191919",
    textColorBase: "#E9E9E9",
  },
  DataTable: {
    tdColor: "#282828",
    tdColorHover: "#202020",
    thColor: "#202020",
    thFontWeight: "500",
  },
};
 */

const customConfig: OrugaOptions = {
  ...bulmaConfig,
  iconComponent: "font-awesome-icon",
  iconPack: "fad",
};

const timeagoOptions = {
  locale: de,
};

app.component("FontAwesomeIcon", FontAwesomeIcon);

app.use(Oruga, customConfig);

app.use(timeago, timeagoOptions);

app.use(createPinia());
app.use(router);

app.mount("#app");
