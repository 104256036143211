import { defineStore } from "pinia";
import { AmazonURLMessage, Country, IMessage, IMessageState, OTPMessage, StatesDE, UnknownMessage } from "./types";
import { apiGet } from "../services/api";

interface ApiMessage {
  message_id: string;
  number_from: string;
  number_to: string;
  body: string;
  received_ts: string;
}

function messageParser(message: ApiMessage): OTPMessage | AmazonURLMessage | UnknownMessage {
  let m;
  if ((m = message.body.match(/Your AMALYZE verification code is (\d{6})/))) {
    return {
      type: "otp",
      from: "AMALYZE",
      otp: m[1],
    };
  } else if (
    (m = message.body.match(
      /(\d{6}) (is your Amazon OTP\. Do not share it with anyone|ist Ihr Amazon-Einmalkennwort \(OTP\)\. Teilen Sie dieses Einmalkennwort nicht mit anderen Personen|è la tua password monouso di Amazon. Non condividerla con nessuno|est votre mot de passe à usage unique Amazon\. Ne le partagez pas avec qui que ce soit|es tu contraseña temporal de Amazon\. No la compartas con nadie)\./
    ))
  ) {
    return {
      type: "otp",
      from: "Amazon",
      otp: m[1],
    };
  } else if (
    (m = message.body.match(
      /(https:\/\/amazon\.([a-z.]+)\/a\/c\/r[^\s]+)\s+Amazon: ([A-Za-z0-9- óé]+) (([A-Z]{2}), )?([A-Z]{2})\. ([A-Za-z0-9-, óé]+)\./
    ))
  ) {
    return {
      type: "url-amazon",
      from: "Amazon",
      url: m[1],
      country: m[6] as Country,
      state: m[5] as StatesDE,
    };
  } else {
    return {
      type: "unknown",
    };
  }
}

function apiTransform(message: ApiMessage): IMessage {
  const messageParsed = messageParser(message);

  return {
    id: message.message_id,
    fromNumber: message.number_from,
    toNumber: message.number_to,
    rawBody: message.body,
    time: new Date(message.received_ts),

    message: messageParsed,
  };
}

export const useMessageStore = defineStore({
  id: "message",

  state: () =>
    ({
      messages: [],
      loading: false,
    } as IMessageState),

  actions: {
    async update() {
      this.loading = true;

      const data: ApiMessage[] = await apiGet({ path: "/sms" });
      this.messages = data.map(apiTransform);

      this.loading = false;
    },
  },
});
