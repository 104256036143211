<template>
  <component
    :is="props.to ? 'router-link' : 'span'"
    :class="['button', props.class, { 'is-loading': props.loading }]"
    :to="props.to"
    @click="() => emit('click')"
  >
    <span :class="['icon', props.iconClass]" :style="{ color: props.iconColor }">
      <FontAwesomeIcon
        v-if="!props.symbol"
        :icon="props.icon"
        :size="props.size"
        :swap-opacity="props.swapOpacity"
      ></FontAwesomeIcon>
      <svg v-else class="icon"><use :href="`#${props.symbol}`"></use></svg>
    </span>
    <span v-if="slots.default"><slot></slot></span>
  </component>
</template>

<script lang="ts" setup>
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { RouteLocationRaw } from "vue-router";
import { useSlots } from "vue";

const slots = useSlots();

interface Props {
  icon?: IconDefinition;
  size?: string;
  class?: string;
  iconColor?: string;
  iconClass?: string;
  symbol?: string;
  to?: RouteLocationRaw;
  loading?: boolean;
  swapOpacity?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  size: undefined,
  class: "",
  iconColor: undefined,
  iconClass: "",
  symbol: undefined,
  to: undefined,
  loading: false,
  swapOpacity: false,
});

const emit = defineEmits<{
  (e: "click"): void;
}>();
</script>

<style scoped></style>
