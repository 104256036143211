<script lang="ts" setup>
import { inject } from "vue";
import { ICustomerRecord } from "../../stores/types";
import { OField, OInput } from "@oruga-ui/oruga-next";

const customer = inject<ICustomerRecord>("customer");
</script>

<template>
  <section>
    <o-field label="Name">
      <o-input :model-value="customer?.name" disabled></o-input>
    </o-field>
    <o-field label="Kundennummer">
      <o-input :model-value="customer?.customerReference" disabled></o-input>
    </o-field>
    <o-field label="CGM">
      <o-input :model-value="customer?.cgm.displayName" disabled></o-input>
    </o-field>
  </section>
</template>

<style scoped></style>
