<script lang="ts" setup>
import { ref } from "vue";
import { OModal } from "@oruga-ui/oruga-next";

const showModal = ref(true);

const emit = defineEmits<{
  (e: "closed"): void;
}>();

const onClose = () => {
  showModal.value = false;
  emit("closed");
};
</script>

<template>
  <o-modal v-model:active="showModal" :on-close="onClose">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <slot name="title"></slot>
        </p>
      </header>
      <section class="modal-card-body">
        <slot></slot>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <slot name="footer"></slot>
      </footer>
    </div>
  </o-modal>
</template>

<style scoped>
section.modal-card-body {
  overflow: visible;
}
</style>
