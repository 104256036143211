<script lang="ts" setup>
import Menu from "./components/Menu.vue";
import { computed } from "vue";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { useAuthStore } from "./stores/auth";

const authStore = useAuthStore();

const authenticated = computed(() => authStore.authenticated);
const authLoading = computed(() => authStore.loading);

setInterval(authStore.heartbeat, 60 * 1000);
</script>

<template>
  <template v-if="!authLoading">
    <Menu v-if="authenticated"></Menu>
    <section class="section heimdall-body">
      <div class="container is-fluid">
        <router-view></router-view>
      </div>
    </section>
  </template>
  <o-loading full-page :active="authLoading">
    <div>
      <div class="block has-text-centered">
        <FontAwesomeIcon :icon="faSpinnerThird" spin size="6x"></FontAwesomeIcon>
      </div>
      <div class="block has-text-centered"><p class="title">Anmeldedaten werden überprüft…</p></div>
    </div>
  </o-loading>
</template>

<style></style>
