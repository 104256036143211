<template>
  <div class="block">
    <div class="level">
      <div class="level-left">
        <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <a href="#">
                <span class="icon is-small">
                  <FontAwesomeIcon :icon="faChartLineUp"></FontAwesomeIcon>
                </span>
                <span>Monitoring</span>
              </a>
            </li>
            <li>
              <router-link :to="{ name: 'reporting-sponsored-ads-troas' }">
                <span class="icon is-small">
                  <FontAwesomeIcon :icon="faMoneyBillTransfer"></FontAwesomeIcon>
                </span>
                <span>TROAS</span>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div class="level-right">
        <o-field>
          <o-select
            v-if="customerFilter.length"
            :model-value="customerId"
            placeholder="Kunde"
            expanded
            @input="changeCustomerId"
          >
            <option :value="-1">Alle</option>
            <option v-for="(item, index) in customerFilter" :key="index" :value="item.id">{{ item.name }}</option>
          </o-select>
        </o-field>
      </div>
    </div>
  </div>

  <div class="block">
    <iframe v-if="config" frameborder="0" allowfullscreen height="1600" width="100%" :src="frameUrl" />
  </div>
</template>

<script lang="ts" setup>
import { faMoneyBillTransfer, faChartLineUp } from "@fortawesome/pro-duotone-svg-icons";
import IconButton from "../../common/IconButton.vue";
import { computed, ComputedRef, onMounted, Ref, ref, watch } from "vue";
import { IDashboardConfig, IReportingSponsoredAdsTemplate, IUserToken } from "../../../stores/types";
import { apiGet } from "../../../services/api";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { useRoute } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useAuthStore } from "../../../stores/auth";

const authStore = useAuthStore();
const activeUser: ComputedRef<IUserToken | null> = computed(() => authStore.token);

const loading = ref(true);
const config: Ref<IDashboardConfig | null> = ref(null);

const fetchData = async ({ customerId = null }: { customerId?: number }) => {
  loading.value = true;

  if (customerId) {
    config.value =
      (await apiGet({
        path: "/reporting/sponsoredAds/troas",
        params: {
          customer_id: customerId || config.value?.customerId,
        },
      })) ?? null;
  } else {
    config.value = (await apiGet({ path: "/reporting/sponsoredAds/troas" })) ?? null;
  }
  loading.value = false;
};

onMounted(() => fetchData({}));

const frameUrl = computed(() => {
  if (config.value) {
    return `https://dashboards.cluvio.com/dashboards/${config.value?.dashboardId}/shared?sharingToken=${config.value?.sharingToken}&sharingSecret=${config.value?.sharingSecret}`;
  } else {
    return null;
  }
});

const customerFilter = computed(() => {
  if (config.value?.customerFilter) {
    return config.value?.customerFilter || [];
  } else {
    return [];
  }
});

const customerId = computed(() => {
  return config.value?.customerId;
});

const changeCustomerId = async (event) => {
  const newId = event.target.value;
  await fetchData({ customerId: newId });
};
</script>

<style scoped lang="scss"></style>
