import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import Home from "./components/Home.vue";
import Messages from "./components/Messages.vue";
import Customers from "./components/Customers.vue";
import Customer from "./components/customer/Customer.vue";
import CustomerDetail from "./components/customer/CustomerDetail.vue";
import CustomerAccount from "./components/customer/CustomerAccount.vue";
import CustomerApi from "./components/CustomerApi.vue";
import CustomerAccounts from "./components/customer/CustomerAccounts.vue";
import Monitoring from "./components/Monitoring.vue";
import Users from "./components/Users.vue";
import LinkGoogleModal from "./components/admin/users/LinkGoogleModal.vue";
import LinkHQModal from "./components/admin/users/LinkHQModal.vue";
import UserEditModal from "./components/admin/users/UserEditModal.vue";
import UserSyncModal from "./components/admin/users/UserSyncModal.vue";
import UserFromGoogleModal from "./components/admin/users/UserFromGoogleModal.vue";
import ReportingContent from "./components/reporting/content/ReportingContent.vue";
import ReportingSponsoredAdsBudget from "./components/reporting/ads/ReportingSponsoredAdsBudget.vue";
import ReportingSponsoredAdsTroas from "./components/reporting/ads/ReportingSponsoredAdsTroas.vue";
import ReportingSponsoredAds from "./components/reporting/sponsoredProducts/ReportingSponsoredAds.vue";
import ReportingSponsoredAdsTemplateEditModal from "./components/reporting/sponsoredProducts/ReportingSponsoredAdsTemplateEditModal.vue";
import ReportingSponsoredAdsExecuteModal from "./components/reporting/sponsoredProducts/ReportingSponsoredAdsExecuteModal.vue";
import { h, resolveComponent } from "vue";
import { useAuthStore } from "./stores/auth";
import ReportingSponsoredAdsAmc from "./components/reporting/ads/ReportingSponsoredAdsAmc.vue";

const routes: RouteRecordRaw[] = [
  { path: "/", component: Home, name: "home" },
  { path: "/auth/keycloak", component: { render: () => h("div") }, name: "auth-cb" },
  {
    path: "/customers",
    name: "customers",
    component: Customers,
  },
  {
    path: "/customers/:customerId",
    component: Customer,
    props: (route) => ({
      ...{ customerId: Number.parseInt(route.params.customerId, 10) },
    }),
    children: [
      {
        path: "detail",
        name: "customer-detail",
        component: CustomerDetail,
      },
      {
        path: "api",
        name: "customer-api",
        component: CustomerApi,
      },
      {
        path: "accounts",
        name: "customer-accounts",
        component: CustomerAccounts,
      },
      {
        path: "account/:accountId",
        name: "customer-account",
        component: CustomerAccount,
        props: (route) => ({
          ...{ customerId: Number.parseInt(route.params.customerId, 10) },
          ...{ accountId: Number.parseInt(route.params.accountId, 10) },
        }),
      },
    ],
  },
  { path: "/monitoring", component: Monitoring, name: "monitoring" },
  { path: "/sms", component: Messages, name: "messages" },
  { path: "/reporting/budget", component: ReportingSponsoredAdsBudget, name: "reporting-sponsored-ads-budget" },
  { path: "/reporting/troas", component: ReportingSponsoredAdsTroas, name: "reporting-sponsored-ads-troas" },
  { path: "/reporting/amc", component: ReportingSponsoredAdsAmc, name: "reporting-sponsored-ads-amc" },
  {
    path: "/reporting/sponsored-ads",
    component: ReportingSponsoredAds,
    name: "reporting-sponsored-ads",
    children: [
      {
        path: "template/new",
        component: ReportingSponsoredAdsTemplateEditModal,
        name: "reporting-sponsored-ads-template-new",
        props: { id: null, mode: "new" },
      },
      {
        path: "template/:id(\\d+)",
        component: ReportingSponsoredAdsTemplateEditModal,
        name: "reporting-sponsored-ads-template-edit",
        props: (route) => ({ id: route.params.id, mode: "edit" }),
      },
      {
        path: "template/:id(\\d+)/copy",
        component: ReportingSponsoredAdsTemplateEditModal,
        name: "reporting-sponsored-ads-template-copy",
        props: (route) => ({ id: route.params.id, mode: "copy" }),
      },
      {
        path: "template/:id(\\d+)/execute",
        component: ReportingSponsoredAdsExecuteModal,
        name: "reporting-sponsored-ads-template-execute",
        props: true,
      },
    ],
  },
  {
    path: "/reporting/content",
    component: ReportingContent,
    name: "reporting-content",
  },
  {
    path: "/admin",
    name: "admin",
    component: { render: () => h(resolveComponent("router-view")) },
    redirect: { name: "users" },
    children: [
      {
        path: "users",
        component: Users,
        name: "users",
        children: [
          {
            path: ":id/google",
            component: LinkGoogleModal,
            name: "link-google",
            props: true,
          },
          {
            path: ":id/hq",
            component: LinkHQModal,
            name: "link-hq",
            props: true,
          },
          {
            path: ":id/edit",
            component: UserEditModal,
            name: "user-edit",
            props: true,
          },
          {
            path: "sync",
            component: UserSyncModal,
            name: "user-sync",
            props: true,
          },
          {
            path: "import/google",
            component: UserFromGoogleModal,
            name: "user-google-import",
            props: true,
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  const authStore = useAuthStore();

  if (from.fullPath === "/" && from.matched.length === 0) {
    // first load
    console.log("initial load");

    if (to.name === "auth-cb") {
      console.log("auth cb");
      const { state, code } = to.query as { state: string; code: string };
      const resp = await authStore.authorize(state, code);
      return { path: resp.returnPath };
    } else {
      console.log("check login");
      const result = await authStore.heartbeat();
      if (!result.authenticated) {
        console.log("no auth");
        return false;
      }
    }

    console.log("initial done");
  }
});

export default router;
