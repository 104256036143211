<script lang="ts" setup>
import { computed, onMounted, Ref, ref, WritableComputedRef } from "vue";
import { useRouter } from "vue-router";
import {
  IReportingSponsoredAdsEntity,
  IReportingSponsoredAdsParameters,
  IReportingSponsoredAdsTemplate,
  IReportingSponsoredAdsTemplateReportType,
} from "../../../stores/types";
import {
  faCalendarRange,
  faCalendars,
  faClipboardListCheck,
  faFilters,
  faFloppyDisks,
  faInputText,
  faPlus,
  faRotateLeft,
  faXmark,
} from "@fortawesome/pro-duotone-svg-icons";
import IconText from "../../common/IconText.vue";
import FieldControl from "../../common/FieldControl.vue";
import Modal from "../../common/Modal.vue";
import IconButton from "../../common/IconButton.vue";

import { apiGet, apiPatch, apiPost } from "../../../services/api";
import DatePicker from "./DatePicker.vue";
import { fromDateString, toDateString } from "../../../utils";
import { OSkeleton, OSwitch } from "@oruga-ui/oruga-next";

const router = useRouter();

const loading = ref(true);
const templateData: Ref<IReportingSponsoredAdsTemplate | null> = ref(null);

const nameFilter = ref("");
const reportName = ref("");
const entities: Ref<IReportingSponsoredAdsEntity[]> = ref([]);
const reportType: Ref<IReportingSponsoredAdsTemplateReportType> = ref("MonthlyMoM");
const reportParameters: Ref<IReportingSponsoredAdsParameters> = ref({
  automatic: true,
  month: undefined,
  year: undefined,
  dateRange: [],
  dateRangePrior: [],
  label: undefined,
});

const props = defineProps<{
  id: string | null;
  mode: "new" | "edit" | "copy";
}>();

const onClose = () => {
  loading.value = false;
  router.push({ name: "reporting-sponsored-ads" });
};

const fetchData = async () => {
  if (props.mode === "new") {
    loading.value = false;

    return;
  }

  loading.value = true;

  const result: IReportingSponsoredAdsTemplate = await apiGet({
    path: `/reporting/sponsoredAds/templates/${props.id}`,
  });
  templateData.value = result;

  nameFilter.value = result.nameFilter;
  reportName.value = result.reportName;
  entities.value = result.entities;
  reportType.value = result.reportType;
  Object.assign(reportParameters.value, result.reportParameters);

  loading.value = false;
};

const addEntity = () => {
  entities.value.push({
    marketplace: "DE",
    entityId: "",
    accountId: 0,
  });
};

const reportYear: WritableComputedRef<string> = computed({
  get(): string {
    if (reportParameters.value.month) {
      const d = fromDateString(reportParameters.value.month);
      return d.getFullYear().toString();
    } else {
      return (new Date().getFullYear() - 1).toString();
    }
  },
  set(newValue: string): void {
    if (reportParameters.value.month) {
      const d = fromDateString(reportParameters.value.month);
      d.setFullYear(parseInt(newValue));
      reportParameters.value.month = toDateString(d);
    } else {
      const d = new Date(parseInt(newValue), 1, 1);
      reportParameters.value.month = toDateString(d);
    }
  },
});

const save = async () => {
  loading.value = true;

  try {
    const payload: Record<string, string | IReportingSponsoredAdsEntity[] | IReportingSponsoredAdsParameters> = {};

    if (props.mode === "new" || props.mode === "copy") {
      payload.nameFilter = nameFilter.value;
      payload.reportName = reportName.value;
      payload.entities = entities.value;
      payload.reportType = reportType.value;
      payload.reportParameters = reportParameters.value;

      await apiPost({ path: `/reporting/sponsoredAds/templates`, payload });
    } else {
      if (nameFilter.value && nameFilter.value !== templateData.value?.nameFilter)
        payload.nameFilter = nameFilter.value;
      if (reportName.value && reportName.value !== templateData.value?.reportName)
        payload.reportName = reportName.value;
      if (entities.value && entities.value !== templateData.value?.entities) payload.entities = entities.value;
      if (reportType.value && reportType.value !== templateData.value?.reportType)
        payload.reportType = reportType.value;
      if (reportParameters.value && reportParameters.value !== templateData.value?.reportParameters)
        payload.reportParameters = reportParameters.value;

      console.log(reportParameters.value);

      if (Object.keys(payload).length) {
        console.log("changed");
        await apiPatch({ path: `/reporting/sponsoredAds/templates/${templateData.value?.id}`, payload });
      } else {
        console.log("not changed");
      }
    }

    loading.value = false;
    onClose();
  } catch (e) {
    console.log(e);
  }

  loading.value = false;
};

onMounted(fetchData);
</script>

<template>
  <Modal @close="onClose">
    <template #title>
      <o-skeleton v-if="loading" animated size="large" :count="1" class="title" height="0.75em"></o-skeleton>
      <IconText v-else :icon="faClipboardListCheck"> {{ reportName }} {{ reportType }} </IconText>
    </template>
    <template #default>
      <FieldControl :icon="faInputText" label="Name" :loading="loading">
        <input v-model="reportName" class="input" type="text" :placeholder="templateData?.reportName" required />
      </FieldControl>
      <FieldControl :icon="faFilters" label="Filter" :loading="loading">
        <input v-model="nameFilter" class="input" type="text" :placeholder="templateData?.nameFilter" required />
      </FieldControl>
      <FieldControl label="Entities">
        <FieldControl v-for="(entity, row) in entities" :key="row" body>
          <FieldControl :loading="loading">
            <div class="select">
              <select v-model="entity.marketplace">
                <option value="DE">DE</option>
                <option value="NL">NL</option>
                <option value="FR">FR</option>
                <option value="ES">ES</option>
                <option value="IT">IT</option>
                <option value="SE">SE</option>
                <option value="GB">UK</option>
                <option value="US">US</option>
                <option value="AU">AU</option>
              </select>
            </div>
          </FieldControl>
          <FieldControl :loading="loading">
            <input v-model="entity.entityId" class="input" type="text" required />
          </FieldControl>
          <FieldControl :loading="loading">
            <input v-model="entity.accountId" class="input" type="number" required />
          </FieldControl>
        </FieldControl>
        <FieldControl :loading="loading">
          <IconButton :icon="faPlus" @click="addEntity">Neue Entity</IconButton>
        </FieldControl>
      </FieldControl>
      <FieldControl :icon="faCalendars" label="Report Typ" :loading="loading">
        <div class="select">
          <select v-model="reportType">
            <option value="MonthlyMoM">MonthlyMoM</option>
            <option value="MonthlyYoY">MonthlyYoY</option>
            <option value="MtD">MtD</option>
            <option value="Yearly">Yearly</option>
            <option value="YtD">YtD</option>
            <option value="Custom">Custom</option>
          </select>
        </div>
      </FieldControl>
      <template v-if="['MonthlyMoM', 'MonthlyYoY'].includes(reportType)">
        <FieldControl label="Monat" :loading="loading">
          <o-switch v-model="reportParameters.automatic" variant="link">Letzter Monat</o-switch>
        </FieldControl>
        <FieldControl :loading="loading">
          <DatePicker v-model="reportParameters.month" type="month" :disabled="reportParameters.automatic" />
        </FieldControl>
      </template>
      <template v-if="reportType === 'Yearly'">
        <FieldControl label="Jahr" :loading="loading">
          <o-switch v-model="reportParameters.automatic" variant="link">Letztes Jahr</o-switch>
        </FieldControl>
        <FieldControl :loading="loading">
          <input v-model="reportYear" class="input" type="number" min="2018" />
        </FieldControl>
      </template>
      <template v-if="reportType === 'Custom'">
        <FieldControl label="Beschreibung" :loading="loading">
          <input v-model="reportParameters.label" class="input" type="text" />
        </FieldControl>
        <FieldControl label="Zeitraum" :loading="loading">
          <DatePicker v-model="reportParameters.dateRange" range />
        </FieldControl>
        <FieldControl label="Zeitraum Vorperiode" :loading="loading">
          <DatePicker v-model="reportParameters.dateRangePrior" range />
        </FieldControl>
      </template>
    </template>
    <template #footer>
      <div class="field is-grouped is-grouped-right">
        <p class="control">
          <IconButton class="is-light" :icon="faXmark" @click="onClose">Schließen</IconButton>
        </p>
        <p v-show="props.mode !== 'new'" class="control">
          <IconButton class="is-danger is-light" :icon="faRotateLeft" :loading="loading" @click="fetchData">
            Rückgangig
          </IconButton>
        </p>
        <p class="control">
          <IconButton
            v-show="props.mode === 'edit'"
            class="is-success"
            :icon="faFloppyDisks"
            :loading="loading"
            @click="save"
            >Speichern</IconButton
          >
          <IconButton v-show="props.mode !== 'edit'" class="is-success" :icon="faPlus" :loading="loading" @click="save"
            >Erstellen</IconButton
          >
        </p>
      </div>
    </template>
  </Modal>
</template>

<style scoped></style>
