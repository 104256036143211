<script lang="ts" setup>
import { computed, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { IUserGoogle } from "../../../stores/types";
import Modal from "../../common/Modal.vue";
import FieldControl from "../../common/FieldControl.vue";
import { apiGet, apiPost } from "../../../services/api";
import { OSkeleton } from "@oruga-ui/oruga-next";

const router = useRouter();

const loading = ref(true);
const unlinkedUsers: Ref<IUserGoogle[]> = ref([]);
const selected = ref("0");
const validSelection = computed(() => selected.value !== "0");

const onClose = () => {
  router.push({ name: "users" });
};

const fetchData = async () => {
  loading.value = true;
  unlinkedUsers.value = (await apiGet({ path: "/users/google/unlinked" })) ?? [];
  if (unlinkedUsers.value.length > 0) {
    selected.value = unlinkedUsers.value[0].googleId;
  }
  loading.value = false;
};

const save = async () => {
  loading.value = true;
  try {
    const user: IUserGoogle | undefined = unlinkedUsers.value.find((user) => user.googleId === selected.value);

    await apiPost({
      path: `/users`,
      payload: {
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        googleId: user?.googleId,
      },
    });

    onClose();
  } catch (e) {
    console.log(e);
  }
  loading.value = false;
};

onMounted(fetchData);
</script>

<template>
  <Modal @close="onClose">
    <template #title>Google Benutzer Importieren</template>
    <template #default>
      <FieldControl :loading="loading">
        <template #loading>
          <o-skeleton animated size="large" height="21em"></o-skeleton>
        </template>
        <template #default>
          <span class="select is-multiple is-fullwidth" style="padding: 0; margin: 0">
            <select v-model="selected" size="10" style="height: auto; padding: 0; width: 100%">
              <option disabled :value="'0'">&mdash; Benutzer Auswählen &mdash;</option>
              <option v-for="user in unlinkedUsers" :key="user.googleId" :value="user.googleId">
                {{ user.fullName }} ({{ user.email }})
              </option>
            </select>
          </span>
        </template>
      </FieldControl>
    </template>
    <template #footer>
      <div class="field is-grouped is-grouped-right">
        <p class="control">
          <button class="button is-light" @click="onClose">Schließen</button>
        </p>
        <p class="control">
          <button
            :class="['button', 'is-success', { 'is-loading': loading }]"
            :disabled="!validSelection"
            @click="save"
          >
            Importieren
          </button>
        </p>
      </div>
    </template>
  </Modal>
</template>

<style scoped></style>
