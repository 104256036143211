<script lang="ts" setup>
import { computed, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { IUserGoogle } from "../../../stores/types";
import Modal from "../../common/Modal.vue";
import FieldControl from "../../common/FieldControl.vue";
import { faBullseyePointer, faXmark } from "@fortawesome/pro-duotone-svg-icons";
import IconButton from "../../common/IconButton.vue";
import { apiGet, apiPut } from "../../../services/api";
import { useUserStore } from "../../../stores/users";
import { OSkeleton } from "@oruga-ui/oruga-next";

const router = useRouter();
const userStore = useUserStore();

const loading = ref(true);
const unlinkedUsers: Ref<IUserGoogle[]> = ref([]);
const selected = ref("0");

const props = defineProps<{
  id: string;
}>();

const editUser = computed(() => userStore.users.find((user) => user.id === parseInt(props.id)));

const onClose = () => {
  router.push({ name: "users" });
};

const fetchData = async () => {
  loading.value = true;
  unlinkedUsers.value = (await apiGet({ path: "/users/google/unlinked" })) ?? [];
  const autoMatch = unlinkedUsers.value.find(
    (user) => user.fullName === editUser.value?.keycloak.fullName || user.email === editUser.value?.keycloak.email,
  );
  if (autoMatch) {
    selected.value = autoMatch.googleId;
  }
  loading.value = false;
};

const save = async () => {
  loading.value = true;
  try {
    await apiPut({ path: `/users/${editUser.value?.id}/google`, payload: { googleId: selected.value } });

    onClose();
  } catch (e) {
    console.log(e);
  }
  loading.value = false;
};

onMounted(fetchData);
</script>

<template>
  <Modal @close="onClose">
    <template #title>Google Konto für {{ editUser?.keycloak.fullName }}</template>
    <template #default>
      <FieldControl :loading="loading">
        <template #loading>
          <o-skeleton animated size="large" height="21em"></o-skeleton>
        </template>
        <template #default>
          <span class="select is-multiple is-fullwidth" style="padding: 0; margin: 0">
            <select v-model="selected" size="10" style="height: auto; padding: 0; width: 100%">
              <option disabled :value="'0'">&mdash; Benutzer Auswählen &mdash;</option>
              <option v-for="user in unlinkedUsers" :key="user.googleId" :value="user.googleId">
                {{ user.fullName }} ({{ user.email }})
              </option>
            </select>
          </span>
        </template>
      </FieldControl>
    </template>
    <template #footer>
      <div class="field is-grouped is-grouped-right">
        <p class="control">
          <IconButton class="is-light" :icon="faXmark" @click="onClose">Schließen</IconButton>
        </p>
        <p class="control">
          <IconButton class="is-success" :icon="faBullseyePointer" :loading="loading" @click="save">
            Zuweisen
          </IconButton>
        </p>
      </div>
    </template>
  </Modal>
</template>

<style scoped></style>
