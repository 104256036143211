import { defineStore } from "pinia";
import { IUserState } from "./types";
import { apiGet } from "../services/api";

export const useUserStore = defineStore({
  id: "user",

  state: () =>
    ({
      users: [],
      loading: false,
    } as IUserState),

  actions: {
    async update() {
      this.loading = true;
      this.users = await apiGet({ path: "/users" });
      this.loading = false;
    },
  },
});
