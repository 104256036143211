<script lang="ts" setup>
import { apiGet, apiPost } from "../services/api";
import { computed, onMounted, onBeforeUnmount, Ref, ref } from "vue";
import { Animal } from "../stores/types";
import { faBuilding } from "@fortawesome/pro-duotone-svg-icons";
import { faCode } from "@fortawesome/pro-duotone-svg-icons";

interface CGM {
  keycloakId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  displayName: string;
  animal: Animal;
  email: string;
  emailVerified: boolean;
  enabled: boolean;
  mfa: boolean;
  roles: string[];
}

interface CustomerRecord {
  id: number;
  customerReference: string;
  name: string;
  active: boolean;
  cgm: CGM;
}

const loading = ref(true);
const records: Ref<CustomerRecord[]> = ref([]);

const props = defineProps<{
  customerId: string;
}>();

const fetchData = async () => {
  loading.value = true;
  records.value = (await apiGet({ path: `/customers/${props.customerId}/api` })) ?? [];
  loading.value = false;
};

const popupWindow: Ref<Window | null> = ref(null);

const doAuth = async () => {
  const width = 1000;
  const height = 800;
  const url = "/lwa/auth.html";

  popupWindow.value = open(
    url,
    "_blank",
    `popup,width=${width},height=${height},left=${screen.width / 2 - width / 2},top=${screen.height / 2 - height / 2}`
  );

  const onMessage = async (event: MessageEvent) => {
    if (event.data.message === "ready") {
      console.log("window ready");
    } else if (event.data.message === "auth") {
      console.log(event.data);

      const result = await apiPost({
        path: `/lwa/cb`,
        payload: {
          state: event.data.state,
          code: event.data.spapi_oauth_code,
          sellingPartnerId: event.data.selling_partner_id,
        },
      });

      console.log(result);

      window.removeEventListener("message", onMessage, false);
    }
  };

  window.addEventListener("message", onMessage, false);

  popupWindow.value?.addEventListener(
    "load",
    () => {
      console.log("loaded");

      popupWindow.value?.window.postMessage({
        message: "redirect",
        // url: `https://sellercentral-europe.amazon.com/apps/authorize/consent?application_id=amzn1.application-oa2-client.97742e1a239143a08d86b133a9a78b25&version=beta`,
        url: `https://vendorcentral.amazon.de/apps/authorize/consent?application_id=amzn1.sellerapps.app.33ddab54-adab-48b8-b255-2e38a4f2653d&redirect_uri=https%3A%2F%2Flocal-dev.ameo.io%2Flwa%2Fcb.html&state=MIoqHEJeZk&version=beta`,
        // url: `https://sellercentral.amazon.de/apps/authorize/consent?application_id=amzn1.sellerapps.app.33ddab54-adab-48b8-b255-2e38a4f2653d&redirect_uri=https%3A%2F%2Flocal-dev.ameo.io%2Flwa%2Fcb.html&state=MIoqHEJeZk&version=beta`,
        // url: `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sellerapps.app.33ddab54-adab-48b8-b255-2e38a4f2653d&redirect_uri=https%3A%2F%2Flocal-dev.ameo.io%2Flwa%2Fcb.html&state=MIoqHEJeZk&version=beta`,
      });
    },
    false
  );
};

setInterval(() => {
  if (popupWindow.value) {
    // console.log(popupWindow.value);
    popupWindow.value?.postMessage({
      message: "ping",
    });
  }
}, 1000);
</script>

<template>
  <div class="level">
    <div class="level-left">
      <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
        <ul>
          <li>
            <router-link :to="{ name: 'customers' }">
              <span class="icon is-small">
                <FontAwesomeIcon :icon="faBuilding"></FontAwesomeIcon>
              </span>
              <span>Kunden</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'customer-detail', params: { customerId: props.customerId } }">
              <span>{{ props.customerId }}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'customer-api', params: { customerId: props.customerId } }">
              <span class="icon is-small">
                <FontAwesomeIcon :icon="faCode"></FontAwesomeIcon>
              </span>
              <span>API</span>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
    <div class="level-right"></div>
  </div>
  <div class="block">
    <h1 class="title is-4">Advertising</h1>
    <div class="columns">
      <div class="column">
        <h2 class="subtitle is-5">Europa</h2>
        <div class="buttons">
          <button class="button" @click="doAuth">Verknüpfen</button>
        </div>
      </div>
      <div class="column">
        <h2 class="subtitle is-5">Nordamerika</h2>
        <div class="buttons">
          <button class="button">Verknüpfen</button>
        </div>
      </div>
      <div class="column">
        <h2 class="subtitle is-5">Naher Osten</h2>
        <div class="buttons">
          <button class="button">Verknüpfen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
