<script lang="ts" setup>
import { onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { Animal, IUser } from "../../../stores/types";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import {
  fa1,
  fa2,
  faCommentSmile,
  faCrown,
  faEnvelope,
  faLock,
  faLockOpen,
  faUser,
  faFloppyDisks,
  faRotateLeft,
  faXmark,
} from "@fortawesome/pro-duotone-svg-icons";
import IconText from "../../common/IconText.vue";
import FieldControl from "../../common/FieldControl.vue";
import Modal from "../../common/Modal.vue";
import { fasHQ } from "../../../icons/faHQ";
import IconButton from "../../common/IconButton.vue";

import { animalIcons } from "../../../utils";
import { apiDelete, apiGet, apiPatch } from "../../../services/api";
import { OSkeleton, OSwitch } from "@oruga-ui/oruga-next";

const router = useRouter();

const loading = ref(true);
const userData: Ref<IUser | null> = ref(null);

const firstName = ref("");
const lastName = ref("");
const displayName = ref("");
const email = ref("");
const enabled = ref(false);
const roles: Ref<string[]> = ref([]);
const animal: Ref<Animal> = ref(null);

const unlinkGoogle = ref(false);
const unlinkHq = ref(false);

const props = defineProps<{
  id: string;
}>();

const onClose = () => {
  router.push({ name: "users" });
};

const fetchData = async () => {
  loading.value = true;

  const result: IUser = await apiGet({ path: `/users/${props.id}` });
  userData.value = result;

  firstName.value = result.keycloak.firstName;
  lastName.value = result.keycloak.lastName;
  displayName.value = result.keycloak.displayName;
  email.value = result.keycloak.email;
  enabled.value = result.keycloak.enabled;
  roles.value = result.keycloak.roles;
  animal.value = result.keycloak.animal;

  unlinkGoogle.value = false;
  unlinkHq.value = false;

  loading.value = false;
};

const save = async () => {
  loading.value = true;
  try {
    const payload: Record<string, string | boolean | Animal> = {};

    if (firstName.value && firstName.value !== userData.value?.keycloak.firstName) payload.firstName = firstName.value;
    if (lastName.value && lastName.value !== userData.value?.keycloak.lastName) payload.lastName = lastName.value;
    if (displayName.value !== userData.value?.keycloak.displayName) payload.displayName = displayName.value;
    if (email.value && email.value !== userData.value?.keycloak.email) payload.email = email.value;
    if (enabled.value !== userData.value?.keycloak.enabled) payload.enabled = enabled.value;
    if (animal.value !== userData.value?.keycloak.animal) payload.animal = animal.value;
    // if (roles.value !== userData.value?.keycloak.roles) payload.roles = roles.value;

    if (Object.keys(payload).length) {
      console.log("changed");
      await apiPatch({ path: `/users/${userData.value?.id}`, payload });
    } else {
      console.log("not changed");
    }

    if (unlinkGoogle.value) {
      await apiDelete({ path: `/users/${userData.value?.id}/google` });
    }
    if (unlinkHq.value) {
      await apiDelete({ path: `/users/${userData.value?.id}/hq` });
    }

    onClose();
  } catch (e) {
    console.log(e);
  }
  loading.value = false;
};

onMounted(fetchData);
</script>

<template>
  <Modal @close="onClose">
    <template #title>
      <o-skeleton v-if="loading" animated size="large" :count="1" class="title" height="0.75em"></o-skeleton>
      <IconText v-else :icon="faUser"> {{ userData?.keycloak.firstName }} {{ userData?.keycloak.lastName }} </IconText>
    </template>
    <template #default>
      <FieldControl label="Name" body>
        <FieldControl :icon="fa1" :loading="loading">
          <input v-model="firstName" class="input" type="text" :placeholder="userData?.keycloak.firstName" required />
        </FieldControl>
        <FieldControl :icon="fa2" :loading="loading">
          <input v-model="lastName" class="input" type="text" :placeholder="userData?.keycloak.lastName" required />
        </FieldControl>
      </FieldControl>
      <FieldControl :icon="faCommentSmile" label="Anzeigename" :loading="loading">
        <input v-model="displayName" class="input" type="text" :placeholder="userData?.keycloak.displayName" required />
      </FieldControl>
      <FieldControl :icon="faEnvelope" label="E-Mail" :loading="loading">
        <input v-model="email" class="input" type="email" :placeholder="userData?.keycloak.email" required />
      </FieldControl>
      <FieldControl :icon="animal ? animalIcons[animal] : faUser" label="Tier" :loading="loading">
        <div class="select">
          <select v-model="animal">
            <option :value="null">keins</option>
            <option v-for="(icon, a) in animalIcons" :key="a" :value="a">{{ a }}</option>
          </select>
        </div>
      </FieldControl>
      <FieldControl v-if="false" :icon="roles.includes('heimdall-admin') ? faCrown : faUser" label="Rolle">
        <select v-model="roles">
          <option :value="['heimdall-user']">Mitarbeiter</option>
          <option :value="['heimdall-user', 'heimdall-admin']">Admin</option>
        </select>
      </FieldControl>
      <FieldControl label="Verbindungen aufheben?" body>
        <FieldControl :loading="loading" loading-height="1.80em">
          <o-switch v-model="unlinkGoogle" variant="danger" :disabled="userData?.google === null">
            <IconText :icon="faGoogle" size="lg" icon-color="#4f86f7">
              {{ userData?.google ? "Verbunden" : "Getrennt" }}
            </IconText>
          </o-switch>
        </FieldControl>
        <FieldControl :loading="loading" loading-height="1.80em">
          <o-switch v-model="unlinkHq" variant="danger" :disabled="userData?.hq === null">
            <IconText :icon="fasHQ" size="lg" icon-color="#fa6d8b">
              {{ userData?.google ? "Verbunden" : "Getrennt" }}
            </IconText>
          </o-switch>
        </FieldControl>
      </FieldControl>
      <FieldControl label="Status" :loading="loading" loading-height="1.80em" width="25%">
        <o-switch v-model="enabled" variant="link">
          <IconText :icon="enabled ? faLockOpen : faLock">
            {{ enabled ? "Aktiv" : "Gesperrt" }}
          </IconText>
        </o-switch>
      </FieldControl>
    </template>
    <template #footer>
      <div class="field is-grouped is-grouped-right">
        <p class="control">
          <IconButton class="is-light" :icon="faXmark" @click="onClose">Schließen</IconButton>
        </p>
        <p class="control">
          <IconButton class="is-danger is-light" :icon="faRotateLeft" :loading="loading" @click="fetchData">
            Rückgangig
          </IconButton>
        </p>
        <p class="control">
          <IconButton class="is-success" :icon="faFloppyDisks" :loading="loading" @click="save">Speichern</IconButton>
        </p>
      </div>
    </template>
  </Modal>
</template>

<style scoped></style>
