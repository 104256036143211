import { IconDefinition, IconName } from "@fortawesome/fontawesome-common-types";

export const fasAVC: IconDefinition = {
  prefix: "fas",
  iconName: "faAVC" as IconName,
  icon: [
    640, // width
    512, // height
    [], // ligatures
    "", // unicode
    "M578.6,368.9c-70,51.7-171.7,79.2-258.5,79.2C203,449,89.8,405.9,2.8,327.5c-6.5-5.9-0.8-14,7.2-9.5\n" +
      "\tc96.5,55.2,205.7,84.2,316.9,84.1c83-0.4,165.1-17.3,241.6-49.5C580.2,347.6,590.2,360.4,578.6,368.9z M607.8,335.7\n" +
      "\tc-9-11.5-59.3-5.4-81.8-2.7c-6.8,0.8-7.9-5.1-1.8-9.5c40.1-28.2,105.9-20.1,113.4-10.6c7.5,9.5-2,75.4-39.6,106.9\n" +
      "\tc-5.8,4.9-11.3,2.3-8.7-4.1C597.8,394.4,616.7,347.2,607.8,335.7z M117.1,290.6c-17.8,0-32-5.1-42.7-15.2\n" +
      "\tc-10.7-10.2-16-23.7-16-40.8c0-18.3,6.5-32.9,19.6-43.8c13-10.9,30.6-16.4,52.6-16.4c14,0,29.8,2.1,47.4,6.4V156\n" +
      "\tc0-13.5-3.1-23.1-9.2-28.6c-6.1-5.5-16.6-8.3-31.4-8.3c-17.3,0-34.2,2.5-50.8,7.5c-5.8,1.8-9.4,2.6-10.9,2.6c-3,0-4.5-2.3-4.5-6.8\n" +
      "\tv-10.2c0-3.3,0.5-5.6,1.5-7.1c1-1.5,3-2.9,6-4.1c7.8-3.5,17.6-6.3,29.5-8.5c11.9-2.1,23.7-3.2,35.5-3.2c23.8,0,41.4,5,52.6,14.9\n" +
      "\tc11.3,9.9,16.9,25,16.9,45.3v128.6c0,5-2.5,7.5-7.5,7.5h-16.2c-4.8,0-7.5-2.4-8.3-7.1l-1.9-12.4c-9,7.8-19,13.8-29.9,18\n" +
      "\tC138.8,288.4,127.9,290.6,117.1,290.6z M126.2,262.4c8.3,0,16.9-1.6,25.9-4.9c9-3.3,17.7-8,25.9-14.3v-38.4\n" +
      "\tc-13.5-3.3-26.8-4.9-39.9-4.9c-28.3,0-42.5,10.9-42.5,32.7c0,9.5,2.6,16.9,7.9,22C108.9,259.8,116.4,262.4,126.2,262.4z\n" +
      "\t M322.1,285.7c-3.5,0-6.1-0.5-7.7-1.5c-1.6-1-3.3-3.9-5.1-8.6l-66.6-166.9c-0.5-1.5-1.1-3-1.7-4.5c-0.6-1.5-0.9-2.9-0.9-4.1\n" +
      "\tc0-3.3,1.9-4.9,5.6-4.9h22.2c3.8,0,6.4,0.5,7.9,1.5c1.5,1,3.1,3.9,4.9,8.6l51.5,146.6l51.5-146.6c1.8-4.8,3.4-7.6,4.9-8.6\n" +
      "\tc1.5-1,4.1-1.5,7.9-1.5h20.7c3.8,0,5.6,1.6,5.6,4.9c0,1.3-0.3,2.6-0.9,4.1c-0.6,1.5-1.2,3-1.7,4.5L354,275.5\n" +
      "\tc-1.8,4.8-3.4,7.6-5.1,8.6c-1.6,1-4.2,1.5-7.7,1.5H322.1z M533.4,289.8c-29.6,0-52.2-8.5-67.9-25.4C449.8,247.5,442,223,442,190.9\n" +
      "\tc0-31.8,8.1-56.5,24.3-73.9c16.2-17.4,39-26.1,68.6-26.1c13.5,0,26.8,2.4,39.9,7.1c2.8,1,4.7,2.3,5.8,3.8c1.1,1.5,1.7,4,1.7,7.5\n" +
      "\tv10.2c0,5-1.6,7.5-4.9,7.5c-1.3,0-3.3-0.4-6-1.1c-10.3-3-20.7-4.5-31.2-4.5c-21.1,0-36.2,5.3-45.5,16c-9.3,10.7-13.9,27.6-13.9,50.9\n" +
      "\tv4.9c0,22.8,4.7,39.5,14.1,50.2c9.4,10.7,24.3,16,44.6,16c10.5,0,21.8-1.8,33.8-5.3c2.8-0.8,4.6-1.1,5.6-1.1c3.3,0,4.9,2.5,4.9,7.5\n" +
      "\tv10.2c0,3.3-0.5,5.6-1.5,7.1c-1,1.5-3,2.9-6,4.1C564,287.2,549.7,289.8,533.4,289.8z",
  ],
};

export const fasASC: IconDefinition = {
  prefix: "fas",
  iconName: "faASC" as IconName,
  icon: [
    640, // width
    512, // height
    [], // ligatures
    "", // unicode
    "M578.6,368.9c-70,51.7-171.7,79.2-258.5,79.2C203,449,89.8,405.9,2.8,327.5c-6.5-5.9-0.8-14,7.2-9.5\n" +
      "\tc96.5,55.2,205.7,84.2,316.9,84.1c83-0.4,165.1-17.3,241.6-49.5C580.2,347.6,590.2,360.4,578.6,368.9z M607.8,335.7\n" +
      "\tc-9-11.5-59.3-5.4-81.8-2.7c-6.8,0.8-7.9-5.1-1.8-9.5c40.1-28.2,105.9-20.1,113.4-10.6c7.5,9.5-2,75.4-39.6,106.9\n" +
      "\tc-5.8,4.9-11.3,2.3-8.7-4.1C597.8,394.4,616.7,347.2,607.8,335.7z M127.5,290.6c-17.8,0-32-5.1-42.7-15.2\n" +
      "\tc-10.7-10.2-16-23.7-16-40.8c0-18.3,6.5-32.9,19.6-43.8c13-10.9,30.6-16.4,52.6-16.4c14,0,29.8,2.1,47.4,6.4V156\n" +
      "\tc0-13.5-3.1-23.1-9.2-28.6c-6.1-5.5-16.6-8.3-31.4-8.3c-17.3,0-34.2,2.5-50.8,7.5c-5.8,1.8-9.4,2.6-10.9,2.6c-3,0-4.5-2.3-4.5-6.8\n" +
      "\tv-10.2c0-3.3,0.5-5.6,1.5-7.1c1-1.5,3-2.9,6-4.1c7.8-3.5,17.6-6.3,29.5-8.5c11.9-2.1,23.7-3.2,35.5-3.2c23.8,0,41.4,5,52.6,14.9\n" +
      "\tc11.3,9.9,16.9,25,16.9,45.3v128.6c0,5-2.5,7.5-7.5,7.5h-16.2c-4.8,0-7.5-2.4-8.3-7.1l-1.9-12.4c-9,7.8-19,13.8-29.9,18\n" +
      "\tC149.1,288.4,138.3,290.6,127.5,290.6z M136.5,262.4c8.3,0,16.9-1.6,25.9-4.9c9-3.3,17.7-8,25.9-14.3v-38.4\n" +
      "\tc-13.5-3.3-26.8-4.9-39.9-4.9c-28.3,0-42.5,10.9-42.5,32.7c0,9.5,2.6,16.9,7.9,22C119.2,259.8,126.7,262.4,136.5,262.4z\n" +
      "\t M330.2,291.3c-21.1,0-40-3.5-56.8-10.5c-3-1.3-5.1-2.6-6.2-4.1c-1.1-1.5-1.7-3.9-1.7-7.1V259c0-4.5,1.5-6.8,4.5-6.8\n" +
      "\tc1.8,0,4.9,0.8,9.4,2.3c16.5,5.3,33.7,7.9,51.5,7.9c12.3,0,21.6-2.4,28-7.1c6.4-4.8,9.6-11.7,9.6-20.7c0-6-1.9-10.9-5.8-14.7\n" +
      "\tc-3.9-3.8-11-7.5-21.2-11.3l-32-12c-27.8-10.3-41.7-27.8-41.7-52.6c0-16.3,6.3-29.5,19-39.5c12.7-10,29.3-15,49.8-15\n" +
      "\tc16.3,0,32.2,2.9,47.8,8.6c3,1,5.1,2.3,6.4,3.9c1.3,1.6,1.9,4.1,1.9,7.3v10.2c0,4.5-1.6,6.8-4.9,6.8c-1.8,0-4.6-0.6-8.6-1.9\n" +
      "\tc-13.3-4-26.8-6-40.6-6c-24.1,0-36.1,8.1-36.1,24.4c0,6.5,2,11.7,6,15.4c4,3.8,11.9,7.9,23.7,12.4l29.3,11.3\n" +
      "\tc14.8,5.8,25.4,12.6,32,20.5c6.5,7.9,9.8,18.1,9.8,30.6c0,17.8-6.6,32-19.9,42.5C369.9,286.1,352.2,291.3,330.2,291.3z M523,289.8\n" +
      "\tc-29.6,0-52.2-8.5-67.9-25.4c-15.7-16.9-23.5-41.4-23.5-73.5c0-31.8,8.1-56.5,24.3-73.9c16.2-17.4,39-26.1,68.6-26.1\n" +
      "\tc13.5,0,26.8,2.4,39.9,7.1c2.8,1,4.7,2.3,5.8,3.8c1.1,1.5,1.7,4,1.7,7.5v10.2c0,5-1.6,7.5-4.9,7.5c-1.3,0-3.3-0.4-6-1.1\n" +
      "\tc-10.3-3-20.7-4.5-31.2-4.5c-21.1,0-36.2,5.3-45.5,16c-9.3,10.7-13.9,27.6-13.9,50.9v4.9c0,22.8,4.7,39.5,14.1,50.2\n" +
      "\tc9.4,10.7,24.3,16,44.6,16c10.5,0,21.8-1.8,33.8-5.3c2.8-0.8,4.6-1.1,5.6-1.1c3.3,0,4.9,2.5,4.9,7.5v10.2c0,3.3-0.5,5.6-1.5,7.1\n" +
      "\tc-1,1.5-3,2.9-6,4.1C553.6,287.2,539.3,289.8,523,289.8z",
  ],
};

export const fasADS: IconDefinition = {
  prefix: "fas",
  iconName: "faADS" as IconName,
  icon: [
    640, // width
    512, // height
    [], // ligatures
    "", // unicode
    "M578.6,368.9c-70,51.7-171.7,79.2-258.5,79.2C203,449,89.8,405.9,2.8,327.5c-6.5-5.9-0.8-14,7.2-9.5\n" +
      "\tc96.5,55.2,205.7,84.2,316.9,84.1c83-0.4,165.1-17.3,241.6-49.5C580.2,347.6,590.2,360.4,578.6,368.9z M607.8,335.7\n" +
      "\tc-9-11.5-59.3-5.4-81.8-2.7c-6.8,0.8-7.9-5.1-1.8-9.5c40.1-28.2,105.9-20.1,113.4-10.6c7.5,9.5-2,75.4-39.6,106.9\n" +
      "\tc-5.8,4.9-11.3,2.3-8.7-4.1C597.8,394.4,616.7,347.2,607.8,335.7z M103.2,290.6c-17.8,0-32-5.1-42.7-15.2\n" +
      "\tc-10.7-10.2-16-23.7-16-40.8c0-18.3,6.5-32.9,19.6-43.8c13-10.9,30.6-16.4,52.6-16.4c14,0,29.8,2.1,47.4,6.4V156\n" +
      "\tc0-13.5-3.1-23.1-9.2-28.6c-6.1-5.5-16.6-8.3-31.4-8.3c-17.3,0-34.2,2.5-50.8,7.5c-5.8,1.8-9.4,2.6-10.9,2.6c-3,0-4.5-2.3-4.5-6.8\n" +
      "\tv-10.2c0-3.3,0.5-5.6,1.5-7.1c1-1.5,3-2.9,6-4.1c7.8-3.5,17.6-6.3,29.5-8.5c11.9-2.1,23.7-3.2,35.5-3.2c23.8,0,41.4,5,52.6,14.9\n" +
      "\tc11.3,9.9,16.9,25,16.9,45.3v128.6c0,5-2.5,7.5-7.5,7.5h-16.2c-4.8,0-7.5-2.4-8.3-7.1l-1.9-12.4c-9,7.8-19,13.8-29.9,18\n" +
      "\tC124.9,288.4,114,290.6,103.2,290.6z M112.3,262.4c8.3,0,16.9-1.6,25.9-4.9c9-3.3,17.7-8,25.9-14.3v-38.4\n" +
      "\tc-13.5-3.3-26.8-4.9-39.9-4.9c-28.3,0-42.5,10.9-42.5,32.7c0,9.5,2.6,16.9,7.9,22C95,259.8,102.5,262.4,112.3,262.4z M325.1,289.8\n" +
      "\tc-16.5,0-30.8-4.1-42.9-12.4c-12-8.3-21.2-19.8-27.6-34.6c-6.4-14.8-9.6-32-9.6-51.5c0-20.8,3.4-38.9,10.2-54.3\n" +
      "\tc6.8-15.4,16.3-27.2,28.6-35.3c12.3-8.1,26.4-12.2,42.5-12.2c21.3,0,40.5,7,57.5,21.1V15c0-5,2.5-7.5,7.5-7.5h22.2\n" +
      "\tc5,0,7.5,2.5,7.5,7.5v263.2c0,5-2.5,7.5-7.5,7.5h-16.5c-2.3,0-4.1-0.5-5.5-1.5c-1.4-1-2.4-3-3.2-6l-3-12\n" +
      "\tc-8.3,7.5-17.7,13.3-28.2,17.5C346.5,287.7,335.9,289.8,325.1,289.8z M336.4,259.7c15.5,0,31.3-5.8,47.4-17.3V136\n" +
      "\tc-7.8-5.8-15.6-10-23.5-12.6c-7.9-2.6-16.6-3.9-26.1-3.9c-34.1,0-51.1,23.6-51.1,70.7C283,236.5,300.8,259.7,336.4,259.7z\n" +
      "\t M527.7,291.3c-21.1,0-40-3.5-56.8-10.5c-3-1.3-5.1-2.6-6.2-4.1c-1.1-1.5-1.7-3.9-1.7-7.1V259c0-4.5,1.5-6.8,4.5-6.8\n" +
      "\tc1.8,0,4.9,0.8,9.4,2.3c16.5,5.3,33.7,7.9,51.5,7.9c12.3,0,21.6-2.4,28-7.1c6.4-4.8,9.6-11.7,9.6-20.7c0-6-1.9-10.9-5.8-14.7\n" +
      "\tc-3.9-3.8-11-7.5-21.2-11.3l-32-12c-27.8-10.3-41.7-27.8-41.7-52.6c0-16.3,6.3-29.5,19-39.5c12.7-10,29.3-15,49.8-15\n" +
      "\tc16.3,0,32.2,2.9,47.8,8.6c3,1,5.1,2.3,6.4,3.9c1.3,1.6,1.9,4.1,1.9,7.3v10.2c0,4.5-1.6,6.8-4.9,6.8c-1.8,0-4.6-0.6-8.6-1.9\n" +
      "\tc-13.3-4-26.8-6-40.6-6c-24.1,0-36.1,8.1-36.1,24.4c0,6.5,2,11.7,6,15.4c4,3.8,11.9,7.9,23.7,12.4l29.3,11.3\n" +
      "\tc14.8,5.8,25.4,12.6,32,20.5c6.5,7.9,9.8,18.1,9.8,30.6c0,17.8-6.6,32-19.9,42.5C567.5,286.1,549.8,291.3,527.7,291.3z",
  ],
};
