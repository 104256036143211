<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faUsers,
  faScrewdriverWrench,
  faUser,
  faUserLock,
  faUserCheck,
  faKey,
  faUserEdit,
  faLockKeyhole,
  faLockKeyholeOpen,
  faPen,
  faKeySkeletonLeftRight,
  faCrown,
  faMobile,
  faEnvelope,
  faLink,
  faXmark,
  faArrowsRotate,
} from "@fortawesome/pro-duotone-svg-icons";

import { animalIcons } from "../utils";

import { fasHQ } from "../icons/faHQ";
import IconText from "./common/IconText.vue";
import IconButton from "./common/IconButton.vue";

import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { useRoute } from "vue-router";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { useUserStore } from "../stores/users";
import { OField, OSwitch, OTable, OTableColumn, OTooltip } from "@oruga-ui/oruga-next";

const userStore = useUserStore();
const route = useRoute();

const filterActive = ref(true);
const syncing = ref(false);

const data = computed(() => userStore.users?.filter((user) => !filterActive.value || user.keycloak.enabled));
const loading = computed(() => userStore.loading);

const fetchData = async () => {
  await userStore.update();
};

watch(
  () => route.name,
  (name) => {
    if (name === "users") fetchData();
  },
);

onMounted(fetchData);

const symbols: Record<string, IconDefinition> = {
  user: faUser,
  "user-check": faUserCheck,
  key: faKey,
  "user-lock": faUserLock,
  "user-edit": faUserEdit,
  lock: faLockKeyhole,
  xmark: faXmark,
  google: faGoogle,
  link: faLink,
  mfa: faKeySkeletonLeftRight,
  "recover-phone": faMobile,
  "recover-email": faEnvelope,
  "recover-none": faXmark,
  admin: faCrown,
  unlock: faLockKeyholeOpen,
  edit: faPen,
  hq: fasHQ,
};
</script>

<template>
  <div class="block">
    <div class="level">
      <div class="level-left">
        <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <router-link :to="{ name: 'admin' }">
                <span class="icon is-small">
                  <FontAwesomeIcon :icon="faScrewdriverWrench"></FontAwesomeIcon>
                </span>
                <span>Admin</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'users' }">
                <span class="icon is-small">
                  <FontAwesomeIcon :icon="faUsers"></FontAwesomeIcon>
                </span>
                <span>Benutzer</span>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div class="level-right">
        <div class="buttons">
          <IconButton :icon="faGoogle" :to="{ name: 'user-google-import' }">Importieren</IconButton>
          <IconButton :loading="syncing" :icon="faArrowsRotate" :to="{ name: 'user-sync' }">Synchronisieren</IconButton>
          <o-field>
            <o-switch v-model="filterActive" variant="info">gesperrte Benutzer ausblenden</o-switch>
          </o-field>
        </div>
      </div>
    </div>
  </div>
  <div class="block">
    <FontAwesomeIcon v-for="(icon, symbol) in symbols" :key="symbol" :icon="icon" :symbol="symbol"></FontAwesomeIcon>
    <FontAwesomeIcon
      v-for="(icon, symbol) in animalIcons"
      :key="symbol"
      :icon="icon"
      :symbol="symbol"
    ></FontAwesomeIcon>

    <o-table
      ref="table"
      :data="data"
      :loading="loading"
      :default-sort="['keycloak.fullName', 'asc']"
      narrowed
      striped
      hoverable
    >
      <o-table-column field="keycloak.active" :width="40" sortable>
        <template #header>
          <IconText symbol="key" />
        </template>
        <template #default="{ row: { keycloak } }">
          <span :class="['icon-text', keycloak.enabled ? 'has-text-success' : 'has-text-danger']">
            <svg class="icon">
              <use :href="keycloak.enabled ? `#${keycloak.animal ?? 'user'}` : '#user-lock'"></use>
            </svg>
          </span>
        </template>
      </o-table-column>

      <o-table-column v-slot="{ row: { keycloak } }" field="keycloak.fullName" label="Name" sortable>
        <o-tooltip :label="keycloak.email" position="right" variant="info">
          <span class="has-text-weight-semibold">{{ keycloak.fullName }}</span>
          <template v-if="keycloak.displayName !== keycloak.firstName"> &mdash; ({{ keycloak.displayName }}) </template>
        </o-tooltip>
      </o-table-column>

      <o-table-column field="keycloak.mfa" position="centered" label="MFA">
        <template #default="{ row: { keycloak } }">
          <o-tooltip
            v-if="keycloak"
            :label="keycloak?.mfa ? 'Multifaktor Ein' : 'Multifaktor Aus'"
            position="right"
            :variant="keycloak?.mfa ? 'success' : 'danger'"
          >
            <IconText
              :symbol="keycloak?.mfa ? 'mfa' : 'xmark'"
              :icon-class="keycloak?.mfa ? 'has-text-success' : 'has-text-danger'"
            />
          </o-tooltip>
        </template>
      </o-table-column>

      <o-table-column field="google.email">
        <template #header>
          <IconText symbol="google" icon-color="#4f86f7">Account</IconText>
        </template>
        <template #default="{ row: { id, google } }">
          <IconText v-if="google" symbol="google" icon-color="#4f86f7">
            {{ google?.email.split("@")[0] }}
          </IconText>
          <div v-else class="buttons has-addons are-small">
            <IconButton class="is-link is-light" symbol="link" :to="{ name: 'link-google', params: { id } }">
              Google Verknüpfen
            </IconButton>
          </div>
        </template>
      </o-table-column>

      <o-table-column field="google.mfa" position="centered" label="MFA">
        <template #default="{ row: { google } }">
          <o-tooltip
            v-if="google"
            :label="google?.mfa ? 'Multifaktor Ein' : 'Multifaktor Aus'"
            position="right"
            :variant="google?.mfa ? 'success' : 'danger'"
          >
            <IconText
              :symbol="google?.mfa ? 'mfa' : 'xmark'"
              :icon-class="google?.mfa ? 'has-text-success' : 'has-text-danger'"
            />
          </o-tooltip>
        </template>
      </o-table-column>

      <o-table-column field="google.recoveryPhone" position="centered" label="Recovery">
        <template #default="{ row: { google } }">
          <o-tooltip
            v-if="google"
            :label="
              google?.recoveryPhone
                ? `Wiederherstellung über ${google?.recoveryPhone} eingerichtet`
                : google?.recoveryEmail
                  ? `Wiederherstellung über ${google?.recoveryEmail} eingerichtet`
                  : 'Wiederherstellung nicht eingerichtet'
            "
            position="right"
            :variant="google?.recoveryPhone || google?.recoveryEmail ? 'success' : 'danger'"
          >
            <IconText
              :symbol="'recover-' + (google?.recoveryPhone ? 'phone' : google?.recoveryEmail ? 'email' : 'none')"
              :icon-class="google?.recoveryPhone || google?.recoveryEmail ? 'has-text-success' : 'has-text-danger'"
            />
          </o-tooltip>
        </template>
      </o-table-column>

      <o-table-column field="hq.email">
        <template #header>
          <IconText symbol="hq" icon-color="#fa6d8b">Account</IconText>
        </template>
        <template #default="{ row: { id, hq } }">
          <IconText v-if="hq" symbol="hq" icon-color="#fa6d8b">
            {{ hq?.email.split("@")[0] }}
          </IconText>
          <div v-else class="buttons has-addons are-small">
            <IconButton class="is-link is-light" symbol="link" :to="{ name: 'link-hq', params: { id } }">
              HQ Verknüpfen
            </IconButton>
          </div>
        </template>
      </o-table-column>

      <o-table-column field="hq.resourceGroup" label="Team">
        <template #default="{ row: { hq } }">{{ hq?.resourceGroup }}</template>
      </o-table-column>

      <o-table-column v-slot="{ row: { id } }">
        <div class="buttons has-addons is-right are-small">
          <IconButton class="is-light" symbol="edit" :to="{ name: 'user-edit', params: { id } }" />
        </div>
      </o-table-column>
    </o-table>
  </div>

  <router-view v-slot="{ Component }">
    <transition name="zoom-out">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<style scoped lang="scss">
.table .button {
  padding: calc(0.4em - 1px) 1em;
  height: 2em;
}

.progress-wrapper {
  position: relative;
}

.progress-value {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: calc(1rem / 1.5);
  line-height: 1rem;
  font-weight: bold;
}

.progress.is-small + .progress-value {
  font-size: calc(0.75rem / 1.5);
  line-height: 0.75rem;
}

.progress.is-medium + .progress-value {
  font-size: calc(1.25rem / 1.5);
  line-height: 1.25rem;
}

.progress.is-large + .progress-value {
  font-size: calc(1.5rem / 1.5);
  line-height: 1.5rem;
}
</style>
