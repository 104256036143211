<script lang="ts" setup>
import { computed, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { TransitionPresets, useTransition } from "@vueuse/core";
import IconButton from "../../common/IconButton.vue";
import { faArrowsRotate } from "@fortawesome/pro-duotone-svg-icons";
import { apiStream } from "../../../services/api";
import { OModal } from "@oruga-ui/oruga-next";

const router = useRouter();

const active = ref(true);

const props = defineProps<{
  id: string;
}>();

const onClose = () => {
  router.push({ name: "reporting-sponsored-ads" });
};

const log: Ref<string[]> = ref([]);
const logText = computed(() => log.value.join("\n"));

const logContainer: Ref<Element | null> = ref(null);

const syncing = ref(false);

const createReport = async () => {
  syncing.value = true;
  // started.value = true;
  console.log("report start");

  log.value = [];

  const onMessage = (msg) => {
    console.log(msg);

    log.value.push(`${msg.msg}`);

    if (logContainer.value) {
      logContainer.value.scrollTo({ top: logContainer.value.scrollHeight });
    }
  };

  await apiStream(`/reporting/sponsoredAds/templates/${props.id}/execute`, onMessage);

  console.log("report done");
  syncing.value = false;
};
</script>

<template>
  <o-modal :active="active" content-class="modal-content" :can-cancel="!syncing" @cancel="onClose">
    <div class="block has-text-centered">
      <h1 class="is-size-2 has-text-white">Report Erstellen</h1>
    </div>
    <div class="block has-text-centered">
      <IconButton
        :loading="syncing"
        :icon="faArrowsRotate"
        class="is-success is-large is-fullwidth"
        @click="createReport"
      >
        Start
      </IconButton>
    </div>
    <div class="block">
      <pre ref="logContainer" style="height: 21em">{{ logText }}</pre>
    </div>
  </o-modal>
</template>

<style scoped></style>
