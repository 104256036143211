import { IconDefinition, IconName } from "@fortawesome/fontawesome-common-types";

export const fasHQ: IconDefinition = {
  prefix: "fas",
  iconName: "faHQ" as IconName,
  icon: [
    118, // width
    118, // height
    [], // ligatures
    "", // unicode
    "M104.8,0h-92C5.7,0,0,5.7,0,12.8v92c0,7.1,5.7,12.8,12.8,12.8h92c7.1,0,12.8-5.7,12.8-12.8v-92 C117.6,5.7,111.9,0,104.8,0z M79.6,88.3c-6.2,4.4-13.7,7-21.9,7C36.9,95.3,20,78.5,20,57.7S36.9,20,57.7,20c8.2,0,15.7,2.6,21.9,7 c4.1,2.9,7.5,6.6,10.2,10.9c3.5,5.7,5.6,12.5,5.6,19.7s-2,14-5.6,19.7C87.1,81.7,83.7,85.4,79.6,88.3z M95.5,96.6 c-5.1,0-9.2-4.1-9.2-9.2c0-5.1,4.1-9.2,9.2-9.2s9.2,4.1,9.2,9.2C104.7,92.5,100.6,96.6,95.5,96.6z M78,41.4L78,41.4l0,9.5v5.4V59 v5.4v9.5v0.3c0,4.3-3.3,7.9-7.7,7.9s-7.7-3.6-7.7-7.9v-0.3v-2.7v-6.8v0h-9.9h-0.2v6.5v3v0.3c0,4.3-3.3,7.9-7.7,7.9s-7.7-3.6-7.7-7.9 v-0.3v-9.9v-5.4v-2.1v-5.4v-9.9v-0.3c0-4.3,3.3-7.9,7.7-7.9s7.7,3.6,7.7,7.9v0.3v3v6.5h0.2h9.9v0v-6.8v-2.7v-0.3 c0-4.3,3.3-7.9,7.7-7.9c4.3,0,7.7,3.6,7.7,7.9V41.4z",
  ],
};

export const fadHQ: IconDefinition = {
  prefix: "fad",
  iconName: "faHQ" as IconName,
  icon: [
    132, // width
    129, // height
    [], // ligatures
    "", // unicode
    [
      "M104.8,0h-92C5.7,0,0,5.7,0,12.8v92c0,7.1,5.7,12.8,12.8,12.8h92c7.1,0,12.8-5.7,12.8-12.8v-92 C117.6,5.7,111.9,0,104.8,0z M79.6,88.3c-6.2,4.4-13.7,7-21.9,7C36.9,95.3,20,78.5,20,57.7S36.9,20,57.7,20c8.2,0,15.7,2.6,21.9,7 c4.1,2.9,7.5,6.6,10.2,10.9c3.5,5.7,5.6,12.5,5.6,19.7s-2,14-5.6,19.7C87.1,81.7,83.7,85.4,79.6,88.3z M95.5,96.6 c-5.1,0-9.2-4.1-9.2-9.2c0-5.1,4.1-9.2,9.2-9.2s9.2,4.1,9.2,9.2C104.7,92.5,100.6,96.6,95.5,96.6z M78,41.4L78,41.4l0,9.5v5.4V59 v5.4v9.5v0.3c0,4.3-3.3,7.9-7.7,7.9s-7.7-3.6-7.7-7.9v-0.3v-2.7v-6.8v0h-9.9h-0.2v6.5v3v0.3c0,4.3-3.3,7.9-7.7,7.9s-7.7-3.6-7.7-7.9 v-0.3v-9.9v-5.4v-2.1v-5.4v-9.9v-0.3c0-4.3,3.3-7.9,7.7-7.9s7.7,3.6,7.7,7.9v0.3v3v6.5h0.2h9.9v0v-6.8v-2.7v-0.3 c0-4.3,3.3-7.9,7.7-7.9c4.3,0,7.7,3.6,7.7,7.9V41.4z",
      "M79.6,27c-6.2-4.4-13.7-7-21.9-7C36.9,20,20,36.9,20,57.7s16.9,37.7,37.7,37.7 c8.2,0,15.7-2.6,21.9-7c4.1-2.9,7.5-6.6,10.2-10.9c3.5-5.7,5.6-12.5,5.6-19.7c0-7.2-2-14-5.6-19.7C87.1,33.7,83.7,29.9,79.6,27 L79.6,27z M95.5,78.1c5.1,0,9.2,4.1,9.2,9.2s-4.1,9.2-9.2,9.2c-5.1,0-9.2-4.1-9.2-9.2C86.3,82.3,90.4,78.1,95.5,78.1L95.5,78.1z M78,41.4v9.5v5.4V59v5.4v9.5v0.3c0,4.3-3.3,7.9-7.7,7.9s-7.7-3.6-7.7-7.9v-0.3v-2.7v-6.8v0h-9.9h-0.2v6.5v3v0.3 c0,4.3-3.3,7.9-7.7,7.9s-7.7-3.6-7.7-7.9v-0.3v-9.9v-5.4v-2.1v-5.4v-9.9v-0.3c0-4.3,3.3-7.9,7.7-7.9s7.7,3.6,7.7,7.9v0.3v3v6.5h0.2 h9.9v0v-6.8v-2.7v-0.3c0-4.3,3.3-7.9,7.7-7.9s7.7,3.6,7.7,7.9L78,41.4L78,41.4z",
    ],
  ],
};
