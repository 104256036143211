import { formatWithOptions } from "date-fns/fp";
import { de } from "date-fns/locale";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import {
  faAlicorn,
  faBat,
  faBee,
  faBird,
  faCatSpace,
  faCow,
  faDeer,
  faDog,
  faDolphin,
  faDove,
  faDragon,
  faDuck,
  faElephant,
  faFish,
  faFrog,
  faHippo,
  faHorseSaddle,
  faLobster,
  faMonkey,
  faNarwhal,
  faOtter,
  faPegasus,
  faPig,
  faRabbit,
  faRam,
  faSheep,
  faShrimp,
  faSnake,
  faSpider,
  faSquid,
  faSquirrel,
  faTeddyBear,
  faTurtle,
  faUnicorn,
  faWhale,
} from "@fortawesome/pro-duotone-svg-icons";

export const dateToStringMinutes = formatWithOptions({ locale: de }, "dd.MM.yyyy HH:mm");

export const animalIcons: Record<string, IconDefinition> = {
  alicorn: faAlicorn,
  bat: faBat,
  bee: faBee,
  bird: faBird,
  cat: faCatSpace,
  cow: faCow,
  deer: faDeer,
  dog: faDog,
  dolphin: faDolphin,
  dove: faDove,
  dragon: faDragon,
  duck: faDuck,
  elephant: faElephant,
  fish: faFish,
  frog: faFrog,
  hippo: faHippo,
  horse: faHorseSaddle,
  lobster: faLobster,
  monkey: faMonkey,
  narwhal: faNarwhal,
  otter: faOtter,
  pegasus: faPegasus,
  pig: faPig,
  rabbit: faRabbit,
  ram: faRam,
  sheep: faSheep,
  shrimp: faShrimp,
  snake: faSnake,
  spider: faSpider,
  squid: faSquid,
  squirrel: faSquirrel,
  teddy: faTeddyBear,
  turtle: faTurtle,
  unicorn: faUnicorn,
  whale: faWhale,
};

export const fromDateString = (v: string | Date): Date => {
  return new Date(v);
};

const pad = (number: number) => {
  if (number < 10) {
    return "0" + number;
  }
  return number;
};

export const toDateString = (v: Date): string => {
  return `${v.getFullYear()}-${pad(v.getMonth() + 1)}-${pad(v.getDate())}`;
};
