<template>
  <div class="block">
    <div class="level">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="buttons">
          <IconButton :icon="faPlus" :to="{ name: 'reporting-sponsored-ads-template-new' }"
            >Template erstellen</IconButton
          >
        </div>
      </div>
    </div>
  </div>

  <div class="block">
    <FontAwesomeIcon v-for="(icon, symbol) in symbols" :key="symbol" :icon="icon" :symbol="symbol"></FontAwesomeIcon>

    <o-table ref="table" :data="records" :loading="loading" :default-sort="['updateTime', 'desc']" narrowed striped>
      <o-table-column v-slot="props" field="reportName" label="Name" sortable>
        {{ props.row.reportName }}
      </o-table-column>
      <o-table-column v-slot="props" field="nameFilter" label="Filter" sortable>
        {{ props.row.nameFilter }}
      </o-table-column>
      <o-table-column v-slot="props" field="entities" label="Entities">
        <div class="field is-grouped is-grouped-multiline" style="flex-direction: column">
          <div v-for="(entity, i) in props.row.entities" :key="i" class="control">
            <div class="tags has-addons">
              <span class="tag is-info">{{ entity.marketplace }}</span>
              <span class="tag">{{ entity.entityId }}</span>
              <span class="tag is-dark">{{ entity.accountId }}</span>
            </div>
          </div>
        </div>
      </o-table-column>
      <o-table-column v-slot="props" field="reportType" label="Typ" sortable>
        {{ props.row.reportType }}
      </o-table-column>
      <o-table-column v-slot="props" field="reportParameters" label="Parameter" sortable>
        <template v-if="['MonthlyMoM', 'MonthlyYoY'].includes(props.row.reportType)">
          <span v-if="props.row.reportParameters.automatic">Letzter Monat</span>
          <span v-else>{{ props.row.reportParameters.month }}</span>
        </template>
        <template v-if="props.row.reportType === 'Yearly'">
          <span v-if="props.row.reportParameters.automatic">Letztes Jahr</span>
          <span v-else>{{ yearFromDateString(props.row.reportParameters.month) }}</span>
        </template>
        <template v-if="props.row.reportType === 'MtD'">Dieser Monat</template>
        <template v-if="props.row.reportType === 'YtD'">Dieses Jahr</template>
        <template v-if="props.row.reportType === 'Custom'">
          <span>{{ props.row.reportParameters.label }}</span>
          <span>, </span>
          <span>{{ formatDateRange(props.row.reportParameters.dateRange) }}</span>
          <span>, </span>
          <span>{{ formatDateRange(props.row.reportParameters.dateRangePrior) }}</span>
        </template>
      </o-table-column>
      <o-table-column v-slot="props">
        <div class="buttons has-addons is-right are-small">
          <IconButton
            class="is-info"
            symbol="execute"
            :to="{ name: 'reporting-sponsored-ads-template-execute', params: { id: props.row.id } }"
          />
          <IconButton
            class="is-light"
            symbol="edit"
            :to="{ name: 'reporting-sponsored-ads-template-edit', params: { id: props.row.id } }"
          />
          <IconButton
            class="is-light"
            symbol="copy"
            :to="{ name: 'reporting-sponsored-ads-template-copy', params: { id: props.row.id } }"
          />
        </div>
      </o-table-column>
    </o-table>
  </div>

  <router-view v-slot="{ Component }">
    <transition name="zoom-out">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script lang="ts" setup>
import { faPen, faPlus, faClone, faArrowDownToBracket } from "@fortawesome/pro-duotone-svg-icons";
import IconButton from "../../common/IconButton.vue";
import { onMounted, Ref, ref, watch } from "vue";
import { IReportingSponsoredAdsTemplate } from "../../../stores/types";
import { apiGet } from "../../../services/api";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { useRoute } from "vue-router";
import { OTable, OTableColumn } from "@oruga-ui/oruga-next";

const table = ref();
const loading = ref(true);
const records: Ref<IReportingSponsoredAdsTemplate[]> = ref([]);

const route = useRoute();

const fetchData = async () => {
  loading.value = true;
  records.value = (await apiGet({ path: "/reporting/sponsoredAds/templates" })) ?? [];
  loading.value = false;
};

onMounted(fetchData);

watch(
  () => route.name,
  (name) => {
    if (name === "reporting-sponsored-ads") fetchData();
  },
);

const yearFromDateString = (v: string | Date): string => {
  return new Date(v).getFullYear().toString();
};

const formatDateRange = (v: string[]): string => {
  if (v.length) {
    return `${v[0]} - ${v[1]}`;
  }
  return "";
};

const symbols: Record<string, IconDefinition> = {
  edit: faPen,
  copy: faClone,
  execute: faArrowDownToBracket,
};
</script>

<style scoped lang="scss">
.table .button {
  padding: calc(0.4em - 1px) 1em;
  height: 2em;
}
</style>
