<template>
  <img v-if="props.url" :src="src" :alt="props.alt" :title="props.alt" />
</template>

<script lang="ts" setup>
import { computed } from "vue";

interface Props {
  url?: string;
  alt?: string;
  size?: number;
}

const props = withDefaults(defineProps<Props>(), {
  url: undefined,
  alt: "",
  size: undefined,
});

const src = computed(() => {
  if (props.size) {
    const urlBase = props.url.split(".").slice(0, -1).join(".");
    return `${urlBase}._SL${props.size}_.jpg`;
  } else {
    return props.url;
  }
});
</script>

<style scoped></style>
