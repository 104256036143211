<template>
  <div class="block">
    <div class="level">
      <div class="level-left"></div>
      <div class="level-right"></div>
    </div>
  </div>

  <div class="block">
    <FontAwesomeIcon v-for="(icon, symbol) in symbols" :key="symbol" :icon="icon" :symbol="symbol"></FontAwesomeIcon>

    <o-field label="Name">
      <o-input v-model="name"></o-input>
    </o-field>
    <o-field label="Marktplatz">
      <o-select v-model="marketplace">
        <option value="DE">DE</option>
        <option value="ES">ES</option>
        <option value="IT">IT</option>
        <option value="FR">FR</option>
        <option value="GB">GB</option>
        <option value="NL">NL</option>
        <option value="SE">SE</option>
        <option value="PL">PL</option>
      </o-select>
    </o-field>
    <o-field label="ASINs" message="einfach ASINs rein, egal ob , oder ; oder neue Zeile als Trennzeichen (oder keins)">
      <o-input v-model="asins" type="textarea"></o-input>
    </o-field>
    <o-button :disabled="syncing" @click="requestReport">Abrufen</o-button>

    <pre ref="logContainer" style="height: 21em" class="mt-4">{{ logText }}</pre>
  </div>

  <router-view v-slot="{ Component }">
    <transition name="zoom-out">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script lang="ts" setup>
import { faPen, faPlus, faClone, faArrowDownToBracket } from "@fortawesome/pro-duotone-svg-icons";
import IconButton from "../../common/IconButton.vue";
import { computed, ComputedRef, onMounted, Ref, ref, watch } from "vue";
import { IReportingSponsoredAdsTemplate, IUserToken } from "../../../stores/types";
import { apiGet, apiStream } from "../../../services/api";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { useRoute } from "vue-router";
import { useAuthStore } from "../../../stores/auth";
import { OButton, OField, OInput, OSelect } from "@oruga-ui/oruga-next";

const route = useRoute();

const authStore = useAuthStore();

const activeUser: ComputedRef<IUserToken | null> = computed(() => authStore.token);

const name = ref(activeUser.value?.displayName);
const marketplace = ref("DE");
const asins = ref("");

const symbols: Record<string, IconDefinition> = {
  edit: faPen,
  copy: faClone,
  execute: faArrowDownToBracket,
};

const log: Ref<string[]> = ref([]);
const logText = computed(() => log.value.join("\n"));

const logContainer: Ref<Element | null> = ref(null);

const syncing = ref(false);

const requestReport = async () => {
  syncing.value = true;
  // started.value = true;

  log.value = [""];

  console.log("crawler start");
  log.value.push("crawler start");

  let gsheet = window.open("", "_blank");

  const onMessage = (msg) => {
    console.log(msg);

    if (msg.type === "sheet") {
      gsheet.location.href = msg.url;
    } else if (msg.type === "result") {
      log.value.push(`${msg.data.asin}`);
    }

    if (logContainer.value) {
      logContainer.value.scrollTo({ top: logContainer.value.scrollHeight });
    }
  };

  await apiStream("/reporting/content/crawler/execute", onMessage, {
    name: name.value,
    marketplace: marketplace.value,
    asins: asins.value,
  });

  console.log("crawler done");
  log.value.push("crawler done");
  syncing.value = false;
};
</script>

<style scoped lang="scss"></style>
