<script lang="ts" setup>
import { apiGet, apiPost } from "../../services/api";
import { computed, inject, onMounted, Ref, ref } from "vue";
import { Animal, ICustomerRecord } from "../../stores/types";
import { faBuilding, faInfo } from "@fortawesome/pro-duotone-svg-icons";
import IconText from "../common/IconText.vue";
import { chromeExtensionId } from "../../settings";
import { OButton, useOruga } from "@oruga-ui/oruga-next";

const props = defineProps<{
  customerId: number;
}>();

const loading = ref(true);
const customer = inject<ICustomerRecord>("customer");

const check = async (type: string) => {
  await apiPost({
    path: `/customers/${props.customerId}/accounts/${props.accountId}/check`,
    payload: {
      type,
      region: "EU",
    },
  });
};

const { oruga } = useOruga();

const doApiAuth = async (authId: number, { country, accountType }: { country: string; accountType: string }) => {
  console.log(authId);
  const port = window.chrome.runtime.connect(chromeExtensionId);
  port.onMessage.addListener(async (msg) => {
    console.log(msg);

    if (msg.type === "credentials") {
      const result = await apiPost({
        path: `/customers/${props.customerId}/accounts/${authId}/credentials`,
      });

      port.postMessage({
        type: "credentials",
        email: result.email,
        password: result.password,
      });
    } else if (msg.type === "mfa") {
      const result = await apiPost({
        path: `/customers/${props.customerId}/accounts/${authId}/credentials`,
      });

      port.postMessage({
        type: "mfa",
        code: result.otp,
      });
    } else if (msg.type === "incognitoRequest") {
      oruga.notification.open({
        message: "Für diese Funktion muss die Erweiterung im Inkognito Modus erlaubt sein!",
        indefinite: true,
        closable: true,
        onClose: () => {
          port.postMessage({ type: "extension" });
        },
      });
    }
  });

  const result = await apiPost({
    path: `/customers/${props.customerId}/accounts/${authId}/api/authorize`,
    payload: {
      country,
      accountType,
      callbackUrl: "https://local-dev.ameo.io/lwa/cb.html",
    },
  });

  console.log(result);

  port.postMessage({
    type: "login",
    authURL: result.authUrl,
  });
};
</script>

<template>
  <section>
    <o-button @click="() => check('vendor')">Check Vendor</o-button>
    <o-button @click="() => check('seller')">Check Seller</o-button>
  </section>
  <section>
    <o-button @click="() => doApiAuth(props.accountId, { accountType: 'seller', country: 'DE' })"
      >Auth Seller EU</o-button
    >
  </section>
  <section>
    <o-button @click="() => doApiAuth(props.accountId, { accountType: 'vendor', country: 'DE' })"
      >Auth Vendor DE</o-button
    >
    <o-button @click="() => doApiAuth(props.accountId, { accountType: 'vendor', country: 'NL' })"
      >Auth Vendor NL</o-button
    >
    <o-button @click="() => doApiAuth(props.accountId, { accountType: 'vendor', country: 'SE' })"
      >Auth Vendor SE</o-button
    >
    <o-button @click="() => doApiAuth(props.accountId, { accountType: 'vendor', country: 'FR' })"
      >Auth Vendor FR</o-button
    >
    <o-button @click="() => doApiAuth(props.accountId, { accountType: 'vendor', country: 'GB' })"
      >Auth Vendor GB</o-button
    >
    <o-button @click="() => doApiAuth(props.accountId, { accountType: 'vendor', country: 'AU' })"
      >Auth Vendor AU</o-button
    >
    <o-button @click="() => doApiAuth(props.accountId, { accountType: 'vendor', country: 'ES' })"
      >Auth Vendor ES</o-button
    >
    <o-button @click="() => doApiAuth(props.accountId, { accountType: 'vendor', country: 'IT' })"
      >Auth Vendor IT</o-button
    >
    <o-button @click="() => doApiAuth(props.accountId, { accountType: 'vendor', country: 'PL' })"
      >Auth Vendor PL</o-button
    >
  </section>
  <section>
    <o-button @click="() => doApiAuth(props.accountId, { accountType: 'advertising', country: 'DE' })"
      >Auth Ads EU</o-button
    >
  </section>
</template>

<style scoped></style>
