<template>
  <o-datepicker
    :model-value="date"
    :locale="locale"
    :position="position"
    :placeholder="placeholder"
    :range="range"
    :type="type"
    @update:model-value="onInput"
  >
  </o-datepicker>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { fromDateString, toDateString } from "../../../utils";
import { ODatepicker } from "@oruga-ui/oruga-next";

interface Props {
  locale?: string;
  position?: "top-right" | "top-left" | "bottom-left";
  placeholder?: string;
  range?: boolean;
  disabled?: boolean;
  type?: string;
  modelValue: string | string[] | Date | Date[];
}

const props = withDefaults(defineProps<Props>(), {
  locale: "de-DE",
  position: "top-left",
  placeholder: "Auswählen",
  type: undefined,
  range: false,
  disabled: false,
});

const date = computed(() => {
  if (props.modelValue instanceof Array) {
    if (props.modelValue.length) {
      console.log([fromDateString(props.modelValue[0]), fromDateString(props.modelValue[1])]);
      return [fromDateString(props.modelValue[0]), fromDateString(props.modelValue[1])];
    } else {
      return [];
    }
  }
  return fromDateString(props.modelValue);
});

const emit = defineEmits<{
  (e: "update:modelValue", value: string | string[]): void;
}>();

const onInput = (value: Date | Date[]) => {
  if (value instanceof Array) {
    emit("update:modelValue", [toDateString(value[0]), toDateString(value[1])]);
  } else {
    console.log(toDateString(value));
    emit("update:modelValue", toDateString(value));
  }
};
</script>
