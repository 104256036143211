<script lang="ts" setup>
import { onMounted, Ref, ref, VNode, h, computed } from "vue";
import { dateToStringMinutes } from "../utils";
import diff_match_patch, { Diff } from "diff-match-patch";
import IconText from "./common/IconText.vue";
import IconButton from "./common/IconButton.vue";
import AmazonImage from "./common/AmazonImage.vue";

import {
  faArrowUpRightFromSquare,
  faCheck,
  faXmark,
  faGlobe,
  faFaceSmileRelaxed,
  faFaceFrownSlight,
  faArrowsRotate,
  faArrowDown,
  faClock,
  faCalendar,
} from "@fortawesome/pro-duotone-svg-icons";

import { faAmazon } from "@fortawesome/free-brands-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { apiDownload, apiGet } from "../services/api";

import { getMarketplaceByCode } from "@bizon/amazon-ids";
import BuyBoxState from "./BuyBoxState.vue";
import { MonitorRecord } from "../stores/types";
import { OCheckbox, OField, OSelect, OTabItem, OTable, OTableColumn, OTabs, OTooltip } from "@oruga-ui/oruga-next";

const table = ref();
const loading = ref(true);
const downloading = ref(false);
const records: Ref<MonitorRecord[]> = ref([]);
const brandFilter: Ref<string | null> = ref(null);
const marketplaceFilter: Ref<string[]> = ref(["DE", "IT", "FR", "ES", "NL", "SE", "PL", "BE", "GB"]);

const fetchData = async () => {
  loading.value = true;
  records.value = (await apiGet({ path: "/content/monitor" })) ?? [];
  loading.value = false;
};

const downloadExcel = async () => {
  downloading.value = true;

  await apiDownload({
    path: "/content/monitor/download",
    params: {
      brand: brandFilter.value,
      marketplaces: marketplaceFilter.value.join(","),
    },
  });

  downloading.value = false;
};

const recordsFiltered = computed(() =>
  records.value
    .filter((r) => brandFilter.value === null || r.brand === brandFilter.value)
    .filter((r) => marketplaceFilter.value.includes(r.marketplace)),
);
const brands = computed(() => [...new Set(records.value.map(({ brand }) => brand))]);
const marketplaces = computed(() => [...new Set(records.value.map(({ marketplace }) => marketplace))]);

const toggle = (row: never) => {
  table.value.toggleDetails(row);
};

const colorDiff = (a: string, b: string) => {
  const dmp = new diff_match_patch();
  const delta = dmp.diff_main(a ?? "", b ?? "");
  dmp.diff_cleanupSemantic(delta);
  const elements: VNode[] = [];

  delta.forEach((d: Diff) => {
    if (d[0] === 0) {
      elements.push(h("span", { class: "diff-equal" }, d[1]));
    } else if (d[0] === 1) {
      elements.push(h("span", { class: "diff-insert" }, d[1]));
    } else if (d[0] === -1) {
      elements.push(h("span", { class: "diff-delete" }, d[1]));
    }
  });

  return h("div", {}, elements);
};

const symbols: Record<string, IconDefinition> = {
  link: faArrowUpRightFromSquare,
  check: faCheck,
  xmark: faXmark,
  globe: faGlobe,
  smile: faFaceSmileRelaxed,
  frown: faFaceFrownSlight,
  amazon: faAmazon,
  clock: faClock,
  calendar: faCalendar,
};

onMounted(fetchData);
</script>

<template>
  <div class="monitoring">
    <div class="block">
      <FontAwesomeIcon v-for="(icon, symbol) in symbols" :key="symbol" :icon="icon" :symbol="symbol"></FontAwesomeIcon>

      <div class="columns">
        <div class="column is-2">
          <o-field>
            <IconButton
              class="is-primary is-light is-fullwidth"
              :loading="loading"
              :icon="faArrowsRotate"
              @click="fetchData"
              >Aktualisieren</IconButton
            >
          </o-field>
          <o-field label="Marke">
            <o-select v-model="brandFilter" placeholder="Marke" expanded>
              <option :value="null">Alle</option>
              <option v-for="(item, index) in brands" :key="index" :value="item">{{ item }}</option>
            </o-select>
          </o-field>
          <o-field label="Marktplatz" :addons="false">
            <o-checkbox
              v-for="(item, index) in marketplaces"
              :key="index"
              v-model="marketplaceFilter"
              :native-value="item"
            >
              <span :class="['fi', `fi-${item.toLowerCase()}`]"></span>
              <span class="ml-2">{{ getMarketplaceByCode(item === "GB" ? "UK" : item).domain }}</span>
            </o-checkbox>
          </o-field>
          <o-field>
            <IconButton class="is-light is-fullwidth" :loading="downloading" :icon="faArrowDown" @click="downloadExcel"
              >Download</IconButton
            >
          </o-field>
        </div>
        <div class="column">
          <o-table
            ref="table"
            :data="recordsFiltered"
            :loading="loading"
            :default-sort="['crawl_time', 'desc']"
            narrowed
            striped
            detailed
            detail-key="id"
            show-detail-icon
            detail-transition="fade"
            paginated
            pagination-position="both"
          >
            <o-table-column v-slot="props" field="asin" label="ASIN" sortable>
              <div class="buttons has-addons are-small">
                <a class="button is-primary is-light" @click="toggle(props.row)">
                  <span class="icon">
                    <svg class="icon"><use href="#amazon"></use></svg>
                  </span>
                  <span>{{ props.row.asin }}</span>
                </a>
              </div>
            </o-table-column>
            <o-table-column v-slot="props" field="marketplace" label="Markt" sortable>
              <div style="padding: calc(0.4125em - 1px) 1em">
                <span :class="['fi', `fi-${props.row.marketplace.toLowerCase()}`]"></span>
              </div>
            </o-table-column>
            <o-table-column v-slot="props" field="data.product_name" label="Produkt" sortable>
              <div class="buttons has-addons are-small">
                <a
                  class="button is-link is-light"
                  target="_blank"
                  :href="`https://${
                    getMarketplaceByCode(props.row.marketplace === 'GB' ? 'UK' : props.row.marketplace).domain
                  }/dp/${props.row.asin}?th=1`"
                >
                  <span class="icon">
                    <svg class="icon"><use href="#link"></use></svg>
                  </span>
                  <span>{{ props.row.brand }} &mdash; {{ props.row.product_name }}</span>
                </a>
              </div>
            </o-table-column>
            <o-table-column v-slot="props" field="ok" label="Gesamt" sortable position="centered">
              <IconText
                :symbol="props.row.ok ? 'smile' : 'frown'"
                :icon-class="props.row.ok ? 'has-text-success' : 'has-text-danger'"
              ></IconText>
            </o-table-column>
            <o-table-column v-slot="props" field="status" label="Status" sortable>
              <IconText
                symbol="globe"
                size="lg"
                :class="props.row.status === 'online' ? 'has-text-success' : 'has-text-danger'"
              >
                {{ props.row.status }}
              </IconText>
            </o-table-column>
            <o-table-column v-slot="props" field="match.title" label="Titel" sortable position="centered">
              <IconText
                :symbol="props.row.match.title ? 'check' : 'xmark'"
                :icon-class="props.row.match.title ? 'has-text-success' : 'has-text-danger'"
              ></IconText>
            </o-table-column>
            <o-table-column v-slot="props" field="match.bulletpoints" label="Bulletpoints" sortable>
              <span
                v-for="(bp, index) in props.row.match.bulletpoints"
                :key="index"
                :class="['icon', bp ? 'has-text-success' : 'has-text-danger']"
              >
                <IconText
                  :symbol="bp ? 'check' : 'xmark'"
                  :icon-class="bp ? 'has-text-success' : 'has-text-danger'"
                ></IconText>
              </span>
            </o-table-column>
            <o-table-column v-slot="props" field="match.buybox" label="Buy Box" sortable position="centered">
              <BuyBoxState :record="props.row" />
            </o-table-column>
            <o-table-column v-slot="props" field="crawl_time" label="Letzter Abruf" position="right" sortable>
              <o-tooltip
                v-if="props.row.crawl_time"
                :label="`${dateToStringMinutes(new Date(props.row.crawl_time))}`"
                position="left"
                variant="info"
              >
                <div style="padding: calc(0.4125em - 1px) 1em">
                  <timeago type="relative" :datetime="props.row.crawl_time" auto-update />
                </div>
              </o-tooltip>
            </o-table-column>
            <template #detail="props">
              <o-tabs expanded class="is-fullwidth">
                <o-tab-item label="Unterschied" icon="arrow-right-arrow-left">
                  <div class="content is-small">
                    <div class="columns">
                      <div class="column is-1">
                        <h4>Bilder</h4>
                        <ul style="list-style: none; margin: 0">
                          <li v-for="(image, index) in props.row.data.images" :key="index">
                            <AmazonImage :url="image.amzn" :alt="`Amazon: ${image.type}`" :size="100" />
                            <AmazonImage :url="image.target" :alt="`Soll: ${image.type}`" :size="100" />
                          </li>
                        </ul>
                      </div>
                      <div class="column">
                        <h4>Titel</h4>
                        <p>
                          <component
                            :is="colorDiff(props.row.data.title.target, props.row.data.title.amzn)"
                          ></component>
                        </p>
                        <h4>Bulletpoints</h4>
                        <ul>
                          <li v-for="(bp, index) in props.row.data.bulletpoints" :key="index">
                            <component :is="colorDiff(bp.target, bp.amzn)"></component>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </o-tab-item>
                <o-tab-item label="Soll" icon="bullseye-arrow">
                  <div class="content is-small">
                    <h4>Titel</h4>
                    <p>{{ props.row.data.title.target }}</p>
                    <h4>Bulletpoints</h4>
                    <ul>
                      <li v-for="(bp, index) in props.row.data.bulletpoints" :key="index">
                        {{ bp.target }}
                      </li>
                    </ul>
                  </div>
                </o-tab-item>
                <o-tab-item label="Amazon" icon="amazon" icon-pack="fab">
                  <div class="content is-small">
                    <div class="columns">
                      <div class="column is-1">
                        <h4>Bilder</h4>
                        <ul style="list-style: none; margin: 0">
                          <li v-for="(image, index) in props.row.data.images" :key="index">
                            <AmazonImage :url="image.amzn" :alt="image.type" :size="100" />
                          </li>
                        </ul>
                      </div>
                      <div class="column">
                        <h4>Titel</h4>
                        <p>{{ props.row.data.title.amzn }}</p>
                        <h4>Bulletpoints</h4>
                        <ul>
                          <li v-for="(bp, index) in props.row.data.bulletpoints" :key="index">
                            {{ bp.amzn }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </o-tab-item>
              </o-tabs>
            </template>
          </o-table>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.monitoring {
  .breadcrumb a {
    color: #4a4a4a;
  }

  .diff-equal {
  }

  .diff-insert {
    color: hsl(141, 71%, 48%);
    text-decoration: underline;
    font-weight: bold;
  }

  .diff-delete {
    color: hsl(348, 100%, 61%);
    text-decoration: line-through;
    font-weight: bold;
  }

  .table .button {
    padding: calc(0.4em - 1px) 1em;
    height: 2em;
  }

  tr {
    line-height: 0.75em;

    div.content {
      line-height: 1.25em;
    }
  }
}
</style>
