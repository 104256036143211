<template>
  <div class="field">
    <label v-if="props.label" class="label">{{ props.label }}</label>
    <div :class="controlClass">
      <template v-if="props.loading">
        <slot name="loading">
          <o-skeleton animated :height="props.loadingHeight" :width="props.loadingWidth"></o-skeleton>
        </slot>
      </template>
      <template v-else>
        <slot></slot>
        <span v-if="hasIcon" class="icon is-left">
          <FontAwesomeIcon :icon="props.icon" size="lg"></FontAwesomeIcon>
        </span>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { computed } from "vue";
import { OSkeleton } from "@oruga-ui/oruga-next";

interface Props {
  label?: string;
  icon?: IconDefinition;
  loading?: boolean;
  loadingHeight?: string;
  loadingWidth?: string;
  body?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  label: undefined,
  loading: false,
  loadingHeight: "2.5em",
  loadingWidth: "100%",
  body: false,
});

const hasIcon = computed(() => !!props.icon);
const controlClass = computed(() => [
  props.body ? "field-body" : "control",
  { "has-icons-left": !props.body && hasIcon.value },
]);
</script>
