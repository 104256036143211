<script lang="ts" setup>
import { apiGet, apiPost } from "../../services/api";
import { computed, onMounted, Ref, ref, provide } from "vue";
import { ICustomerRecord, IAmazonLogin, ISellerAccount, IVendorAccount, IApiAdvAccount } from "../../stores/types";
import {
  faBuilding,
  faInfo,
  faArrowUpRightFromSquare,
  faKeySkeletonLeftRight,
} from "@fortawesome/pro-duotone-svg-icons";
import { faAmazon } from "@fortawesome/free-brands-svg-icons";
import { fasHQ } from "../../icons/faHQ";
import IconText from "../common/IconText.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { chromeExtensionId } from "../../settings";
import { OMenu, OMenuItem, useOruga } from "@oruga-ui/oruga-next";
import { fasASC, fasAVC, fasADS } from "../../icons/faAmazon";

const props = defineProps<{
  customerId: number;
}>();

const helperInstalled = ref(false);
const loading = ref(true);
const customer: Ref<ICustomerRecord | null> = ref(null);
const accounts: Ref<IAmazonLogin[]> = ref([]);

const fetchData = async () => {
  loading.value = true;
  customer.value = (await apiGet({ path: `/customers/${props.customerId}` })) ?? [];
  accounts.value = (await apiGet({ path: `/customers/${props.customerId}/accounts` })) ?? [];
  loading.value = false;
};

const sellerAccounts = computed<ISellerAccount[]>(() => {
  const sellerInfo: ISellerAccount[] = accounts.value
    .map((l) => l.sellerInfo)
    .flat()
    .filter((l) => l) as ISellerAccount[];
  return sellerInfo.filter(
    (obj, index) =>
      sellerInfo.findIndex((item) => item.sellerId === obj.sellerId && item.marketplace === obj.marketplace) === index,
  );
});

const vendorAccounts = computed<IVendorAccount[]>(() => {
  const vendorInfo: IVendorAccount[] = accounts.value
    .map((l) => l.vendorInfo)
    .flat()
    .filter((l) => l) as IVendorAccount[];
  return vendorInfo.filter((obj, index) => vendorInfo.findIndex((item) => item.vendorId === obj.vendorId) === index);
});

const advAccounts = computed<IApiAdvAccount[]>(() => {
  const advInfo: IApiAdvAccount[] = accounts.value
    .map((l) => l.apiAdvInfo)
    .flat()
    .filter((l) => l) as IApiAdvAccount[];
  return advInfo.filter((obj, index) => advInfo.findIndex((item) => item.profileId === obj.profileId) === index);
});

provide("customer", customer);

const init = async () => {
  console.log(chromeExtensionId);
  window.chrome.runtime.sendMessage(chromeExtensionId, { type: "ping" }, (data) => {
    if (data.type === "pong") {
      helperInstalled.value = true;
    }
  });

  await fetchData();
};

onMounted(init);

const { oruga } = useOruga();

const doLogin = async (authId: number, { country, accountType }: { country: string; accountType: string }) => {
  console.log(authId);
  const port = window.chrome.runtime.connect(chromeExtensionId);
  port.onMessage.addListener(async (msg) => {
    console.log(msg);

    if (msg.type === "credentials") {
      const result = await apiPost({
        path: `/customers/${customer.value?.id}/accounts/${authId}/credentials`,
      });

      port.postMessage({
        type: "credentials",
        email: result.email,
        password: result.password,
      });
    } else if (msg.type === "mfa") {
      const result = await apiPost({
        path: `/customers/${customer.value?.id}/accounts/${authId}/credentials`,
      });

      port.postMessage({
        type: "mfa",
        code: result.otp,
      });
    } else if (msg.type === "incognitoRequest") {
      oruga.notification.open({
        message: "Für diese Funktion muss die Erweiterung im Inkognito Modus erlaubt sein!",
        indefinite: true,
        closable: true,
        onClose: () => {
          port.postMessage({ type: "extension" });
        },
      });
    }
  });

  const result = await apiPost({
    path: `/customers/${customer.value?.id}/accounts/${authId}/urls`,
    payload: {
      country,
      accountType,
    },
  });

  console.log(result);

  port.postMessage({
    type: "login",
    authURL: result.authUrl,
    nextURL: result.nextUrl,
  });
};
</script>

<template>
  <div class="block">
    <div class="level">
      <div class="level-left">
        <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <router-link :to="{ name: 'customers' }">
                <span class="icon is-small">
                  <FontAwesomeIcon :icon="faBuilding"></FontAwesomeIcon>
                </span>
                <span>Kunden</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'customer-detail', params: { customerId: props.customerId } }">
                <span>{{ customer?.name }}</span>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div class="level-right"></div>
    </div>
  </div>
  <div class="block">
    <div class="columns">
      <aside class="column is-one-third">
        <o-menu :accordion="false" :activable="false">
          <o-menu label="Allgemein" list-class="mb-4">
            <li>
              <router-link
                :to="{ name: 'customer-detail', params: { customerId: props.customerId } }"
                active-class="is-active"
              >
                <IconText :icon="faInfo">Stammdaten</IconText>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'customer-accounts', params: { customerId: props.customerId } }"
                active-class="is-active"
              >
                <IconText :icon="faKeySkeletonLeftRight">Accounts</IconText>
              </router-link>
            </li>
            <li v-if="customer?.hqId">
              <a
                target="_blank"
                :href="`https://ameo-agentur.hellohq.io/hqframe?menuitemid=4006&url=kunden_details_allgemein&companyid=${customer?.hqId}`"
              >
                <span class="icon-text">
                  <span class="icon">
                    <FontAwesomeIcon :icon="fasHQ"></FontAwesomeIcon>
                  </span>
                  <span>HQ</span>
                  <span class="icon">
                    <FontAwesomeIcon :icon="faArrowUpRightFromSquare" size="xs"></FontAwesomeIcon>
                  </span>
                </span>
              </a>
            </li>
          </o-menu>
          <o-menu list-class="mb-4">
            <template #label>
              <IconText :icon="fasADS">Advertising</IconText>
            </template>
            <o-menu-item v-for="(info, index) in advAccounts" :key="index">
              <template #label>
                <span :class="['ml-2', 'fi', `fi-${info.countryCode.toLowerCase()}`]"></span>
                <span class="ml-2">{{ info.accountName }}</span>
                <span class="ml-2">
                  {{ info.accountType }}
                </span>
              </template>
            </o-menu-item>
          </o-menu>
          <o-menu v-if="helperInstalled" list-class="mb-4">
            <template #label>
              <IconText :icon="fasAVC">Vendor</IconText>
            </template>
            <o-menu-item
              v-for="(info, index) in vendorAccounts"
              :key="index"
              @click="async () => await doLogin(info.authId, { country: info.marketplace, accountType: 'vendor' })"
            >
              <template #label>
                <span :class="['ml-2', 'fi', `fi-${info.marketplace.toLowerCase()}`]"></span>
                <span class="ml-2">{{ info.vendorName }}</span>
                <span class="ml-2">
                  <FontAwesomeIcon :icon="faArrowUpRightFromSquare" size="xs"></FontAwesomeIcon>
                </span>
              </template>
            </o-menu-item>
          </o-menu>
          <o-menu v-if="helperInstalled" list-class="mb-4">
            <template #label>
              <IconText :icon="fasASC">Seller</IconText>
            </template>
            <o-menu-item
              v-for="(info, index) in sellerAccounts"
              :key="index"
              @click="async () => await doLogin(info.authId, { country: info.marketplace, accountType: 'seller' })"
            >
              <template #label>
                <span :class="['ml-2', 'fi', `fi-${info.marketplace.toLowerCase()}`]"></span>
                <span class="ml-2">{{ info.sellerName }} — {{ info.marketplace }}</span>
                <span class="ml-2">
                  <FontAwesomeIcon :icon="faArrowUpRightFromSquare" size="xs"></FontAwesomeIcon>
                </span>
              </template>
            </o-menu-item>
          </o-menu>
          <o-menu label="Accounts" list-class="mb-4">
            <li v-for="account in accounts" :key="account.id">
              <router-link
                :to="{ name: 'customer-account', params: { id: props.customerId, accountId: account.id } }"
                active-class="is-active"
              >
                <IconText :icon="faAmazon">{{ account.email.replace("@ameo-agentur.de", "") }}</IconText>
              </router-link>
            </li>
          </o-menu>
        </o-menu>
      </aside>
      <div class="column">
        <router-view :key="customer?.id" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
