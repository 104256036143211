<template>
  <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item icon-text" active-class="is-active" to="/">
        <span class="icon is-large">
          <FontAwesomeIcon :icon="faEye" size="2x"></FontAwesomeIcon>
        </span>
        <span v-if="activeUser.roles.includes('heimdall-user')">Heimdall</span>
        <span v-if="activeUser.roles.includes('heimdall-customer')">Analytics Hub</span>
      </router-link>
    </div>
    <div class="navbar-menu">
      <div class="navbar-start">
        <router-link
          v-if="activeUser.roles.includes('heimdall-user')"
          class="navbar-item icon-text"
          active-class="is-active"
          :to="{ name: 'customers' }"
        >
          <span class="icon is-large">
            <FontAwesomeIcon :icon="faBuilding" size="2x"></FontAwesomeIcon>
          </span>
          <span>Kunden</span>
        </router-link>

        <div class="navbar-item has-dropdown is-hoverable">
          <p class="navbar-link icon-text">
            <span class="icon is-large">
              <FontAwesomeIcon :icon="faChartLineUp" size="2x"></FontAwesomeIcon>
            </span>
            <span>Monitoring</span>
          </p>

          <div class="navbar-dropdown is-right is-boxed">
            <router-link
              v-if="
                activeUser.roles.includes('heimdall-user') || activeUser.permissions.includes('hub-monitor-content')
              "
              class="navbar-item icon-text"
              active-class="is-active"
              :to="{ name: 'monitoring' }"
            >
              <span class="icon is-small">
                <FontAwesomeIcon :icon="faFileMagnifyingGlass"></FontAwesomeIcon>
              </span>
              <span>Content</span>
            </router-link>
            <router-link
              v-if="activeUser.roles.includes('heimdall-user') || activeUser.permissions.includes('hub-monitor-budget')"
              class="navbar-item icon-text"
              active-class="is-active"
              :to="{ name: 'reporting-sponsored-ads-budget' }"
            >
              <span class="icon is-small">
                <FontAwesomeIcon :icon="faMoneyBillTrendUp"></FontAwesomeIcon>
              </span>
              <span>Budget</span>
            </router-link>
            <router-link
              v-if="activeUser.roles.includes('heimdall-user') || activeUser.permissions.includes('hub-monitor-troas')"
              class="navbar-item icon-text"
              active-class="is-active"
              :to="{ name: 'reporting-sponsored-ads-troas' }"
            >
              <span class="icon is-small">
                <FontAwesomeIcon :icon="faMoneyBillTransfer"></FontAwesomeIcon>
              </span>
              <span>TROAS</span>
            </router-link>
            <router-link
              v-if="activeUser.roles.includes('heimdall-user') || activeUser.permissions.includes('hub-monitor-amc')"
              class="navbar-item icon-text"
              active-class="is-active"
              :to="{ name: 'reporting-sponsored-ads-amc' }"
            >
              <span class="icon is-small">
                <FontAwesomeIcon :icon="faCloud"></FontAwesomeIcon>
              </span>
              <span>AMC</span>
            </router-link>
          </div>
        </div>

        <router-link
          v-if="activeUser.roles.includes('heimdall-user')"
          class="navbar-item icon-text"
          active-class="is-active"
          :to="{ name: 'messages' }"
        >
          <span class="icon is-large">
            <FontAwesomeIcon :icon="faMessageSms" size="2x"></FontAwesomeIcon>
          </span>
          <span>SMS</span>
        </router-link>

        <div v-if="activeUser.roles.includes('heimdall-user')" class="navbar-item has-dropdown is-hoverable">
          <p class="navbar-link icon-text">
            <span class="icon is-large">
              <FontAwesomeIcon :icon="faFileSpreadsheet" size="2x"></FontAwesomeIcon>
            </span>
            <span>Reports</span>
          </p>

          <div class="navbar-dropdown is-right is-boxed">
            <router-link
              class="navbar-item icon-text"
              active-class="is-active"
              :to="{ name: 'reporting-sponsored-ads' }"
            >
              <span>Sponsored Ads</span>
            </router-link>
            <router-link class="navbar-item icon-text" active-class="is-active" :to="{ name: 'reporting-content' }">
              <span>Content</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="navbar-end">
        <div v-if="activeUser.roles.includes('heimdall-admin')" class="navbar-item has-dropdown is-hoverable">
          <router-link class="navbar-link icon-text" active-class="is-active" :to="{ name: 'admin' }">
            <span class="icon is-large">
              <FontAwesomeIcon :icon="faScrewdriverWrench" size="2x"></FontAwesomeIcon>
            </span>
            <span>Admin</span>
          </router-link>

          <div class="navbar-dropdown is-right is-boxed">
            <router-link class="navbar-item icon-text" active-class="is-active" :to="{ name: 'users' }">
              <span class="icon">
                <FontAwesomeIcon :icon="faUsers"></FontAwesomeIcon>
              </span>
              <span>Benutzer</span>
            </router-link>
          </div>
        </div>

        <p class="navbar-item icon-text">
          <span class="icon is-large">
            <FontAwesomeIcon
              :icon="activeUser.animal ? animalIcons[activeUser.animal] : faSquareUser"
              size="2x"
            ></FontAwesomeIcon>
          </span>
          <span>{{ activeUser.fullName }}</span>
        </p>
        <div class="navbar-item">
          <div class="field is-grouped">
            <p class="control">
              <a :class="['button', 'is-danger', { 'is-loading': logoutLoading }]" @click="logout">
                <span>Abmelden</span>
                <span class="icon">
                  <FontAwesomeIcon :icon="faArrowRightFromBracket"></FontAwesomeIcon>
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { computed, ComputedRef, ref } from "vue";
import { RouterLink } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faEye,
  faMoneyBillTrendUp,
  faFileMagnifyingGlass,
  faMoneyBillTransfer,
  faCloud,
} from "@fortawesome/pro-duotone-svg-icons";
import { faBuilding } from "@fortawesome/pro-duotone-svg-icons";
import { faMessageSms } from "@fortawesome/pro-duotone-svg-icons";
import { faArrowRightFromBracket } from "@fortawesome/pro-duotone-svg-icons";
import { faSquareUser } from "@fortawesome/pro-duotone-svg-icons";
import { faUsers } from "@fortawesome/pro-duotone-svg-icons";
import { faScrewdriverWrench } from "@fortawesome/pro-duotone-svg-icons";
import { faFileSpreadsheet } from "@fortawesome/pro-duotone-svg-icons";
import { faChartLineUp } from "@fortawesome/pro-duotone-svg-icons";

import { animalIcons } from "../utils";

import { IUserToken } from "../stores/types";
import { useAuthStore } from "../stores/auth";

const authStore = useAuthStore();

const activeUser: ComputedRef<IUserToken | null> = computed(() => authStore.token);

const logoutLoading = ref(false);

const logout = async () => {
  logoutLoading.value = true;
  try {
    await authStore.logout();
  } finally {
    setTimeout(() => (logoutLoading.value = false), 10000);
  }
};
</script>

<style scoped></style>
