<script lang="ts" setup>
import { apiGet } from "../services/api";
import { computed, onMounted, Ref, ref } from "vue";
import { ICustomerRecord } from "../stores/types";
import { faBuilding } from "@fortawesome/pro-duotone-svg-icons";

const table = ref();
const loading = ref(true);
const records: Ref<ICustomerRecord[]> = ref([]);
const filterActive = ref(true);

const filteredRecords = computed(() => records.value.filter((customer) => !filterActive.value || customer.active));

const fetchData = async () => {
  loading.value = true;
  records.value = (await apiGet({ path: "/customers" })) ?? [];
  loading.value = false;
};

import { format } from "date-fns";
import { de } from "date-fns/locale";
import { OField, OSwitch, OTable, OTableColumn } from "@oruga-ui/oruga-next";

const dateToString = (dateStr) => (dateStr ? format(new Date(dateStr), "MMMM yyyy", { locale: de }) : "");
// const dateToString = (dateStr) => format(new Date(dateStr), "d. MMMM yyyy", { locale: de });
// const dateToString = (dateStr) => format(new Date(dateStr), "dd.MM.yyyy", { locale: de });

onMounted(fetchData);
</script>

<template>
  <div class="block">
    <div class="level">
      <div class="level-left">
        <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <li>
              <router-link :to="{ name: 'customers' }">
                <span class="icon is-small">
                  <FontAwesomeIcon :icon="faBuilding"></FontAwesomeIcon>
                </span>
                <span>Kunden</span>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div class="level-right">
        <div class="buttons">
          <o-field>
            <o-switch v-model="filterActive" variant="info">inaktive Kunden ausblenden</o-switch>
          </o-field>
        </div>
      </div>
    </div>
  </div>
  <div class="block">
    <o-table ref="table" :data="filteredRecords" :loading="loading" :default-sort="['name', 'asc']" narrowed striped>
      <o-table-column v-slot="props" field="name" label="Kunde" sortable searchable>
        <router-link :to="{ name: 'customer-detail', params: { customerId: props.row.id } }">{{
          props.row.name
        }}</router-link>
      </o-table-column>
      <o-table-column v-slot="props" field="cgm.displayName" label="CGM" sortable searchable>
        {{ props.row.cgm.displayName }}
      </o-table-column>
      <o-table-column v-slot="props" field="creationTime" label="Erstellt" sortable>
        {{ dateToString(props.row.creationTime) }}
      </o-table-column>
    </o-table>
  </div>
</template>

<style scoped></style>
