<template>
  <div class="block">
    <article class="notification is-warning">
      <div class="media">
        <span class="icon media-left">
          <FontAwesomeIcon :icon="faExclamationTriangle" size="2x"></FontAwesomeIcon>
        </span>
        <div class="media-content is-size-5">Bitte keine Links von unbekannten Absendern öffnen!</div>
      </div>
    </article>
  </div>
  <div class="block">
    <button class="button is-light is-success is-fullwidth" @click="fetchData">Aktualisieren</button>
  </div>
  <div class="block">
    <o-table :data="data" :loading="loading">
      <o-table-column v-slot="props" field="fromNumber" label="Absender">
        <o-tooltip
          v-if="props.row.message.type !== 'unknown'"
          :label="`${props.row.fromNumber} &mdash; Verifiziert`"
          position="right"
          variant="success"
        >
          {{ props.row.message.from }}
          <FontAwesomeIcon :icon="faShieldCheck" size="1x" :style="{ color: 'green' }"></FontAwesomeIcon>
        </o-tooltip>
        <o-tooltip v-else label="Unbekannter Absender" position="right" variant="danger">
          {{ props.row.fromNumber }}
          <FontAwesomeIcon :icon="faExclamationTriangle" size="1x" :style="{ color: 'red' }"></FontAwesomeIcon>
        </o-tooltip>
      </o-table-column>
      <o-table-column v-slot="props" field="toNumber" label="Empfänger">
        {{ props.row.toNumber }}
      </o-table-column>
      <o-table-column v-slot="props" field="rawBody" label="Nachricht">
        <span v-if="props.row.message.type === 'otp'">
          <a class="icon-text" @click="() => copyFeedback(props.row.message.otp)">
            <span>{{ props.row.message.otp }}</span>
            <span class="icon">
              <FontAwesomeIcon :icon="faCopy"></FontAwesomeIcon>
            </span>
          </a>
        </span>
        <span v-else-if="props.row.message.type === 'url-amazon'">
          <a class="icon-text" :href="props.row.message.url" @click.prevent="() => openPopup(props.row.message.url)">
            <span>Login aus {{ stateMap[props.row.message.state] ?? countryMap[props.row.message.country] }}</span>
            <span class="icon">
              <FontAwesomeIcon :icon="faArrowUpRightFromSquare"></FontAwesomeIcon>
            </span>
          </a>
        </span>
        <span v-else>{{ props.row.rawBody }}</span>
      </o-table-column>
      <o-table-column v-slot="props" field="time" label="Empfangen">
        <o-tooltip :label="`${dateToStringMinutes(props.row.time)}`" position="left" variant="info">
          <timeago type="relative" :datetime="props.row.time" auto-update />
        </o-tooltip>
      </o-table-column>
    </o-table>
  </div>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { faExclamationTriangle } from "@fortawesome/pro-duotone-svg-icons";
import { faShieldCheck } from "@fortawesome/pro-duotone-svg-icons";
import { faCopy } from "@fortawesome/pro-duotone-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-duotone-svg-icons";

import { computed, onMounted } from "vue";
import { useClipboard } from "@vueuse/core";

import { useOruga } from "@oruga-ui/oruga-next";
import { CountryMap, StateMap } from "../stores/types";
import { dateToStringMinutes } from "../utils";
import { useMessageStore } from "../stores/messages";

const countryMap: CountryMap = {
  DE: "Deutschland",
};

const stateMap: StateMap = {
  BW: "Baden-Württemberg",
  BY: "Bayern",
  BE: "Berlin",
  BB: "Brandenburg",
  HB: "Bremen",
  HH: "Hamburg",
  HE: "Hessen",
  MV: "Mecklenburg-Vorpommern",
  NI: "Niedersachsen",
  NW: "Nordrhein-Westfalen",
  RP: "Rheinland-Pfalz",
  SL: "Saarland",
  SN: "Sachsen",
  ST: "Sachsen-Anhalt",
  SH: "Schleswig-Holstein",
  TH: "Thüringen",
};

const messageStore = useMessageStore();

const data = computed(() => messageStore.messages);
const loading = computed(() => messageStore.loading);

const fetchData = async () => {
  await messageStore.update();
};

const { copy } = useClipboard();

const { oruga } = useOruga();

const copyFeedback = async (text: string) => {
  await copy(text);

  oruga.notification.open({
    message: `"${text}" kopiert!`,
    rootClass: "toast-notification",
    position: "top",
    variant: "success",
    duration: 3000,
  });
};

const openPopup = async (url: string) => {
  window.open(url, "Amazon Popup", "left=400,top=200,popup,width=450,height=650");
};

onMounted(fetchData);
</script>

<style scoped></style>
