<template>
  <o-tooltip :label="tooltip" position="right" :variant="variant">
    <IconText :symbol="symbol" :icon-class="`has-text-${variant}`"></IconText>
  </o-tooltip>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { MonitorRecord } from "../stores/types";
import IconText from "./common/IconText.vue";
import { formatDistance } from "date-fns";
import { de } from "date-fns/locale";

interface Props {
  record?: MonitorRecord;
}

const props = withDefaults(defineProps<Props>(), {
  record: undefined,
});

const tooltip = computed(() => {
  if (props.record?.match.buybox) {
    if (props.record?.availability.availability === "NOW") {
      const shippingTime = props.record?.availability.shipping_time;
      if (shippingTime[0] <= 24) {
        return "Sofort Verfügbar";
      } else {
        if (shippingTime[1] < 24) {
          return `Versandfertig in ${shippingTime[0]} - ${shippingTime[1]} Stunden`;
        } else {
          return `Versandfertig in ${Math.round(shippingTime[0] / 24)} - ${Math.round(shippingTime[1] / 24)} Tagen`;
        }
      }
    } else if (props.record?.availability.availability === "FUTURE_WITH_DATE") {
      const availableDate = new Date(props.record?.availability.available_date);
      return `Verfügbar ${formatDistance(availableDate, new Date(), { addSuffix: true, locale: de })}`;
    } else {
      return "";
    }
  } else {
    return "Keine Buy Box";
  }
});

const symbol = computed(() => {
  if (props.record?.match.buybox) {
    if (props.record?.availability.availability === "NOW") {
      if (props.record?.availability.shipping_time[0] <= 24) {
        return "check";
      } else {
        return "clock";
      }
    } else if (props.record?.availability.availability === "FUTURE_WITH_DATE") {
      return "calendar";
    } else {
      return "";
    }
  } else {
    return "xmark";
  }
});

const variant = computed(() => {
  if (props.record?.match.buybox) {
    if (props.record?.availability.availability === "NOW") {
      if (props.record?.availability.shipping_time[0] <= 24) {
        return "success";
      } else {
        return "warning";
      }
    } else if (props.record?.availability.availability === "FUTURE_WITH_DATE") {
      return "warning";
    } else {
      return "";
    }
  } else {
    return "danger";
  }
});
</script>
