<script lang="ts" setup>
import { computed, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { TransitionPresets, useTransition } from "@vueuse/core";
import IconButton from "../../common/IconButton.vue";
import { faArrowsRotate } from "@fortawesome/pro-duotone-svg-icons";
import { apiStream } from "../../../services/api";
import { OModal } from "@oruga-ui/oruga-next";

const router = useRouter();

const active = ref(true);

const onClose = () => {
  router.push({ name: "users" });

  // log.value = [];
  // progressValue.value = 0;
  // progressTotal.value = 0;
};

const log: Ref<string[]> = ref([]);
const logText = computed(() => log.value.join("\n"));

const logContainer: Ref<Element | null> = ref(null);

const progressValue = ref(0);
const progressTotal = ref(0);

const syncing = ref(false);

const progressAnimation = useTransition(progressValue, {
  duration: 100,
  transition: TransitionPresets.easeInOutCubic,
});

const progressBarValue = computed(() => {
  if (progressAnimation.value === 0 && syncing.value) {
    return null;
  } else {
    return progressAnimation.value;
  }
});

const syncUsers = async () => {
  syncing.value = true;
  // started.value = true;
  console.log("sync start");

  log.value = [];

  const onMessage = (msg) => {
    console.log(msg);
    progressTotal.value = msg.total;
    progressValue.value = msg.progress;

    if (msg.info) {
      log.value.push(`${msg.state}: ${msg.info?.email}`);
    } else {
      log.value.push(`${msg.state}`);
    }

    if (logContainer.value) {
      logContainer.value.scrollTo({ top: logContainer.value.scrollHeight });
    }
  };

  await apiStream("/users/sync", onMessage);

  console.log("sync done");
  syncing.value = false;
};
</script>

<template>
  <o-modal :active="active" content-class="modal-content" :can-cancel="!syncing" @cancel="onClose">
    <div class="block has-text-centered">
      <h1 class="is-size-2 has-text-white">Benutzer Synchronisieren</h1>
    </div>
    <div class="block has-text-centered">
      <IconButton :loading="syncing" :icon="faArrowsRotate" class="is-success is-large is-fullwidth" @click="syncUsers">
        Start
      </IconButton>
    </div>
    <div class="block">
      <progress class="progress is-info" :value="progressBarValue" :max="progressTotal"></progress>
    </div>
    <div class="block">
      <pre ref="logContainer" style="height: 21em">{{ logText }}</pre>
    </div>
  </o-modal>
</template>

<style scoped>
progress.progress {
  height: 2em;
  border-radius: 4px;
}
</style>
