import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { apiBase } from "../settings";
import * as Sentry from "@sentry/vue";
import { AuthState, IUserToken } from "./types";
import { jwtDecode } from "jwt-decode";
import { apiGet } from "../services/api";

export const useAuthStore = defineStore({
  id: "auth",

  state: () =>
    ({
      tokenRaw: useStorage("accessToken", null),
      loading: false,
    }) as AuthState,

  getters: {
    authenticated: ({ tokenRaw }): boolean => tokenRaw != null,
    token: ({ tokenRaw }): IUserToken | null => (tokenRaw ? jwtDecode(tokenRaw) : null),
  },

  actions: {
    async loginUrl(): Promise<string> {
      const url = new URL(`${apiBase}/auth/login`);
      url.search = new URLSearchParams({ returnPath: window.location.pathname }).toString();
      const response = await fetch(url.toString());
      const data = await response.json();

      return data.url;
    },

    async logoutUrl(): Promise<string> {
      const url = new URL(`${apiBase}/auth/logout`);
      url.search = new URLSearchParams({ returnPath: window.location.pathname }).toString();
      const response = await fetch(url.toString());
      const data = await response.json();

      return data.url;
    },

    async ssoLogin() {
      window.location.href = await this.loginUrl();
    },

    updateToken(token: string) {
      this.tokenRaw = token;

      if (this.token) {
        Sentry.setUser({
          id: this.token.keycloakId,
          email: this.token.email,
          username: this.token.displayName,
        });
      }
    },

    async authorize(state: string, code: string): Promise<{ returnPath: string }> {
      const response = await fetch(`${apiBase}/auth/authorize`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ state, code }),
      });

      const data = await response.json();

      this.updateToken(data.accessToken);

      return data;
    },

    async logout() {
      this.tokenRaw = null;
      Sentry.configureScope((scope) => scope.setUser(null));
      window.location.href = await this.logoutUrl();
    },

    async heartbeat(): Promise<{ authenticated: boolean }> {
      console.log("heartbeat");
      // await new Promise((r) => setTimeout(r, 2000));
      const result = await apiGet({ path: "/auth/heartbeat" });

      if (result.accessToken) {
        this.updateToken(result.accessToken);
      }

      if (this.loading && result.authenticated) {
        this.loading = false;
      }

      return { authenticated: !!result.authenticated };
    },
  },
});
