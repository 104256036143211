/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiBase, wsBase } from "../settings";
import { IWebsocketTicket } from "../stores/types";
import { useAuthStore } from "../stores/auth";

interface FetchParameters {
  method: string;
  path: string | URL;
  params?: any;
  payload?: any;
}

interface RequestWithParams {
  path: string | URL;
  params?: any;
}

interface RequestWithParamsPayload {
  path: string | URL;
  params?: any;
  payload?: any;
}

export const apiFetch = async ({ method, path, params = null, payload = null }: FetchParameters): Promise<any> => {
  const authStore = useAuthStore();

  let requestData: RequestInit;

  if (payload) {
    requestData = {
      method: method,
      headers: {
        Authorization: `Bearer ${authStore.tokenRaw}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
  } else {
    requestData = {
      method: method,
      headers: {
        Authorization: `Bearer ${authStore.tokenRaw}`,
      },
    };
  }

  const url = new URL(`${apiBase}${path}`);

  if (params) {
    for (const [key, value] of Object.entries(params)) {
      if (value) {
        url.searchParams.append(key, <string>value);
      }
    }
  }

  const response = await fetch(url, requestData);

  if (response.status == 401) {
    await authStore.ssoLogin();
  } else if (response.status == 200) {
    if (response.headers.get("Content-Type") === "application/json") {
      return await response.json();
    } else {
      return response;
    }
  } else if (response.status >= 400) {
    throw response;
  }
  return new Response();
};

const websocketTicket = async (): Promise<IWebsocketTicket> => {
  return await apiFetch({ method: "GET", path: "/auth/websocket" });
};

export const apiStream = async (
  path: string,
  onMessage: (msg: Record<string, unknown>) => void,
  initialData?: any,
): Promise<WebSocket> => {
  const ticket: IWebsocketTicket = await websocketTicket();

  return new Promise(function (resolve, reject) {
    const webSocket = new WebSocket(`${wsBase}${path}?token=${ticket.token}`);

    webSocket.onmessage = (event) => {
      onMessage(JSON.parse(event.data));
    };

    webSocket.onclose = function () {
      resolve(webSocket);
    };

    webSocket.onopen = function () {
      if (initialData) {
        webSocket.send(JSON.stringify(initialData));
      }
    };

    webSocket.onerror = function (err) {
      reject(err);
    };
  });
};

export const apiGet = async ({ path, params = null }: RequestWithParams) => {
  return await apiFetch({ method: "GET", path, params });
};

export const apiPost = async ({ path, params = null, payload = null }: RequestWithParamsPayload) => {
  return await apiFetch({ method: "POST", path, params, payload });
};

export const apiDelete = async ({ path, params = null, payload = null }: RequestWithParamsPayload) => {
  return await apiFetch({ method: "DELETE", path, params, payload });
};

export const apiPut = async ({ path, params = null, payload = null }: RequestWithParamsPayload) => {
  return await apiFetch({ method: "PUT", path, params, payload });
};

export const apiPatch = async ({ path, params = null, payload = null }: RequestWithParamsPayload) => {
  return await apiFetch({ method: "PATCH", path, params, payload });
};

export const apiDownload = async ({ path, params = null }: RequestWithParams) => {
  const resp = await apiFetch({ method: "GET", path, params });
  const file = window.URL.createObjectURL(await resp.blob());
  window.location.assign(file);
};
