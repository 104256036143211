<template>
  <span class="icon-text">
    <span :class="['icon', props.iconClass]" :style="{ color: props.iconColor }">
      <FontAwesomeIcon v-if="!props.symbol" :icon="props.icon" :size="props.size"></FontAwesomeIcon>
      <svg v-else class="icon"><use :href="`#${props.symbol}`"></use></svg>
    </span>
    <span><slot></slot></span>
  </span>
</template>

<script lang="ts" setup>
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface Props {
  icon?: IconDefinition;
  size?: string;
  iconColor?: string;
  iconClass?: string;
  symbol?: string;
}

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  size: undefined,
  iconColor: undefined,
  iconClass: "",
  symbol: undefined,
});
</script>

<style scoped></style>
